import ContainerOnlineCourse from '../../../Component/Dashboard/OnlineCourse/OnlineCourse'

const OnlineCourse = () => {
  return (
    <div className='page-body'>
      <ContainerOnlineCourse />
    </div>
  )
}

export default OnlineCourse