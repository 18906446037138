import LoginWithImageTwoContainer from "../../../../Component/OtherPages/Authentication/LoginWithImageTwo/LoginWithImageTwo"

const LoginWithImageTwo = () => {
  return (
    <div className='page-body'>
      <LoginWithImageTwoContainer />
    </div>
  )
}

export default LoginWithImageTwo