import SearchResultContainer from "../../../Component/Application/SearchResult/SearchResult"

const SearchResult = () => {
  return (
    <div className='page-body'>
      <SearchResultContainer />
    </div>
  )
}

export default SearchResult