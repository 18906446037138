import { Container, Row } from "reactstrap";
import Breadcrumbs from "../../../CommonElements/Breadcrumbs/Breadcrumbs";
import { Dashboard, Default } from "../../../utils/Constant";
import SalesChart from "./SalesChart/SalesChart";
import TotalRevenue from "./TotalRevenue/TotalRevenue";
import SpecialOffer from "./SpecialOffer/SpecialOffer";
import TotalSales from "./TotalSales/TotalSales";
import LatestOrders from "./LatestOrders/LatestOrders";
import TeamActivity from "./TeamActivity/TeamActivity";
import LiveProduct from "./LiveProduct/LiveProduct";
import TopRevenueProduct from "./TopRevenueProduct/TopRevenueProduct";
import UserVisitsByDay from "./UserVisitsByDay/UserVisitsByDay";
import AddNewProduct from "./AddNewProduct/AddNewProduct";

const ContainerDefault = () => {
  return (
    <>
      <Breadcrumbs mainTitle={Dashboard} parent={Dashboard} />
      <Container fluid>
        <Row>
          <SalesChart />
          <TotalRevenue />
          <TopRevenueProduct /> 
          <TeamActivity />
          <LatestOrders />
          <UserVisitsByDay />
          <TotalSales />
        </Row>
      </Container>
    </>
  );
};

export default ContainerDefault;
