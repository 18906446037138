import DefaultStyleContainer from "../../../Component/Buttons/DefaultStyle/DefaultStyle"

const DefaultStyle = () => {
  return (
    <div className='page-body'>
      <DefaultStyleContainer />
    </div>
  )
}

export default DefaultStyle