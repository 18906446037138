import UserCardsContainer from "../../../../Component/Application/Users/UserCards/UserCards"

const UserCards = () => {
  return (
    <div className='page-body'>
      <UserCardsContainer />
    </div>
  )
}

export default UserCards