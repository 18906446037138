import RatingContainer from "../../../Component/BonusUi/Rating/Rating"

const Rating = () => {
  return (
    <div className='page-body'>
      <RatingContainer />
    </div>
  )
}

export default Rating