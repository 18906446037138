import LearningListContainer from "../../../../Component/Miscellaneous/Learning/LearningList/LearningList"

const LearningList = () => {
  return (
    <div className='page-body'>
      <LearningListContainer />
    </div>
  )
}

export default LearningList