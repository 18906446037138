import SweetAlertContainer from "../../../Component/BonusUi/SweetAlert/SweetAlert"

const SweetAlert = () => {
  return (
    <div className='page-body'>
      <SweetAlertContainer />
    </div>
  )
}

export default SweetAlert