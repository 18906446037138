import React, { useMemo, useState, useEffect } from 'react';
import { Card, CardBody, CardHeader, Col, Input, Label } from 'reactstrap';
import DataTable, { TableColumn } from "react-data-table-component";
import { Modal, ModalBody } from 'reactstrap';
import { Btn, H4 } from '../../AbstractElements';

const Distributers = () => {
    const [filterText, setFilterText] = useState("");
    const [tableData, setTableData] = useState<any[]>([]); 
    const [simpleModal, setSimpleModal] = useState(false);
    const toggle = () => setSimpleModal(!simpleModal);
  
    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const response = await fetch('https://mobileapp.hemexhealth.org/get_distributers');
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            console.log(data); // Logging fetched data for debugging
            setTableData(data.modules.reverse());
        } catch (error) {
            console.error('Error fetching data:', error);
            // Handle error gracefully, e.g., show error message to the user
        }
    };

    const filteredItems = tableData.filter(item => {
        // Filter based on name, email, or any other relevant field
        return (
            item.name.toLowerCase().includes(filterText.toLowerCase()) ||
            item.email.toLowerCase().includes(filterText.toLowerCase())
            // Add more conditions as needed
        );
    });

    const subHeaderComponentMemo = useMemo(() => {
        return (
            <div id="API-1_filter" className="dataTables_filter d-flex align-items-center">
                <Label className="me-1">{"Search"}:</Label>
                <Input onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFilterText(e.target.value)} type="search" value={filterText} />
            </div>
        );
    }, [filterText]);
    
    const columns: TableColumn<any>[] = [
        {
            name: 'Sl No',
            cell: (row: any, index: number) => index + 1,
            sortable: true,
        },
        {
            name: 'Organization Type',
            selector: (row) => row.organization_type,

            sortable: true,
        },
        {
            name: 'Organization Name',
            selector: (row) => row.organization_name,

            // selector: 'organization_name',
            sortable: true,
        },
        {
            name: 'District',
            selector: (row) => row.district,

            sortable: true,
        },
        {
            name: 'State',
            selector: (row) => row.state,
            sortable: true,
        },
        {
            name: 'Name',
            selector: (row) => row.name,
            sortable: true,
        },
        {
            name: 'Mobile',
            selector: (row) => row.mobile,

            sortable: true,
        },
        {
            name: 'Email',
            selector:(row) => row.email,
            sortable: true,
        },
        {
            name: 'Status',
            // selector: (row) =>row.status,
            cell: (row: any) => (
                <Btn className="btn-pill btn-sm" color={row.status === 1 ? "success" : "danger"} onClick={toggle}>
                    {row.status === 1 ? "Active" : "Inactive"}
                </Btn>
            ),
            sortable: true,
        },
        
    ];

    return (
        <div className='page-body'>
            <Col sm="12">
                <Card>
                    <CardHeader>
                        <H4 className="mb-3">Users List</H4>
                    </CardHeader>
                    <CardBody>
                        <div className="table-responsive">
                            <div id="API-1" className="dataTables_wrapper">
                                <DataTable data={filteredItems} columns={columns} striped={true} pagination className="display" subHeader subHeaderComponent={subHeaderComponentMemo} />
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </Col>
        </div>
    );
}

export default Distributers;
