import ApexChartContainer from "../../../Component/Charts/ApexChart/ApexChart"

const ApexChart = () => {
  return (
    <div className='page-body'>
      <ApexChartContainer />
    </div>
  )
}

export default ApexChart