import TasksContainer from "../../../Component/Application/Tasks/Tasks"

const Tasks = () => {
  return (
    <div className='page-body'>
      <TasksContainer />
    </div>
  )
}

export default Tasks