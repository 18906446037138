import DetailsCourseContainer from "../../../../Component/Miscellaneous/Learning/DetailsCourse/DetailsCourse"

const DetailsCourse = () => {
  return (
    <div className='page-body'>
      <DetailsCourseContainer />
    </div>
  )
}

export default DetailsCourse