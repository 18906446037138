import ListViewContainer from "../../../../Component/Miscellaneous/JobSearch/ListView/ListView"

const ListView = () => {
  return (
    <div className='page-body'>
      <ListViewContainer />
    </div>
  )
}

export default ListView