import React, { useState } from 'react';
import { Container, Row, Form, FormGroup, Input, Label, Card, CardBody, CardHeader, Col } from 'reactstrap';
import Breadcrumbs from '../../../CommonElements/Breadcrumbs/Breadcrumbs';
import { Faq } from '../../../utils/Constant';
import { H5 } from '../../../AbstractElements';
import { toast } from 'react-toastify';

const AddFaq: React.FC = () => {
  const [formData, setFormData] = useState({
    category: 'Faq',
    question: '',
    answer: ''
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      const response = await fetch('https://mobileapp.hemexhealth.org/addfaq', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });

      if (!response.ok) {
        throw new Error('Failed to add FAQ');
      }
      
      const data = await response.json();
      toast.success(data.message);

      // Reset the form data after successful submission
      setFormData({
        ...formData,
        question: '',
        answer: ''
      });

    } catch (error) {
      console.error('Error adding FAQ:', error);
      toast.error('Failed to add FAQ');
    }
  };

  return (
    <div className="page-body">
      <Breadcrumbs mainTitle={Faq} parent={Faq} />
      <Container fluid>
        <div className="faq-wrap">
          <Card className="card-mb-faq">
            <CardHeader className="faq-header card-no-border pb-0">
              <H5>Add FAQ</H5>
            </CardHeader>
            <CardBody className="faq-body">
              <Form className="needs-validation" onSubmit={handleSubmit}>
                <Row>
                  <Col sm="12">
                    <FormGroup>
                      <Label for="question">Title:</Label>
                      <Input type="text" id="question" name="question" value={formData.question} onChange={handleChange} placeholder="Title" required />
                    </FormGroup>
                    <FormGroup>
                      <Label for="category">Category:</Label>
                      <Input type="select" id="category" name="category" bsSize="sm" value={formData.category} onChange={handleChange}>
                        <option value="Faq">Faq</option>
                        <option value="Article">Articles</option>
                        <option value="Knowledge Base">Knowledge Base</option>
                      </Input>
                    </FormGroup>
                    <FormGroup>
                      <Label for="answer">Answer:</Label>
                      <Input type="textarea" id="answer" name="answer" value={formData.answer} onChange={handleChange} placeholder="Answer" required />
                    </FormGroup>
                    <button type="submit" className="btn btn-primary">Submit</button>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
        </div>
      </Container>
    </div>
  );
};

export default AddFaq;
