import GoogleChartContainer from "../../../Component/Charts/GoogleChart/GoogleChart"

const GoogleChart = () => {
  return (
    <div className='page-body'>
      <GoogleChartContainer />
    </div>
  )
}

export default GoogleChart