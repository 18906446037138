import AddProductContainer from "../../../../Component/Application/Ecommerce/AddProduct/AddProduct"

const AddProduct = () => {
  return (
    <div className='page-body'>
      <AddProductContainer />
    </div>
  )
}

export default AddProduct