import InputMaskContainer from "../../../../Component/Forms/FormsControl/InputMask/InputMask"

const InputMask = () => {
  return (
    <div className='page-body'>
      <InputMaskContainer />
    </div>
  )
}

export default InputMask