import ClipBoardContainer from "../../../../Component/Forms/FormsWidgets/ClipBoard/ClipBoard"

const ClipBoard = () => {
  return (
    <div className='page-body'>
      <ClipBoardContainer />
    </div>
  )
}

export default ClipBoard