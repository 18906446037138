import CalenderContainer from "../../../Component/Application/Calender/Calender"

const Calender = () => {
  return (
    <div className='page-body'>
      <CalenderContainer />
    </div>
  )
}

export default Calender