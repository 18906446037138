import ModalContainer from "../../../Component/Ui-Kits/Modal/Modal"

const Modal = () => {
  return (
    <div className='page-body'>
      <ModalContainer />
    </div>
  )
}

export default Modal