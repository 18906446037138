import { MenuItem } from "../../Types/Layout/Sidebar";
import React from "react";

const userRole: string | null = localStorage.getItem('userRole');
const userPermissionsString: string | null = localStorage.getItem('permissions');

interface Permission {
  role: string;
  identifier: string;
  actions: {
    view: boolean;
    read: boolean;
    edit: boolean;
    delete: boolean;
  };
}

interface UserPermission {
  role: string;
  identifier: string;
  actions: {
    view: boolean;
    read: boolean;
    edit: boolean;
    delete: boolean;
  };
  permissions: Permission[]; // Add permissions property if it exists
}

type UserPermissions = UserPermission[];

let MenuList: MenuItem[] = [];

function checkPermission(permissions: UserPermissions, identifier: string, action: keyof Permission['actions']): boolean {
  // console.log(permissions);
  const userPermission = permissions[0]['permissions'].find(permission => permission.identifier === identifier);
  // console.log(userPermission);
  // console.log(userPermission);
  return userPermission ? userPermission.actions[action] : false;  
}

if (userRole !== 'Admin' && userPermissionsString) {
  let userPermissions: UserPermissions = [];

  try {
    userPermissions = JSON.parse(userPermissionsString);
  } catch (error) {
    console.error('Error parsing user permissions:', error);
  }

  if (userRole !== 'Admin' && userPermissionsString) {
    userPermissions = JSON.parse(userPermissionsString);
  }

  MenuList = [
    {
      title: "General",
      lanClass: "lan-1",
      Items: [
        { path: `${process.env.PUBLIC_URL}/`, icon: "home", title: "Dashboard", type: "link" },
        ...(checkPermission(userPermissions, 'users', 'view') ? [{ path: `${process.env.PUBLIC_URL}/distributer/list`, icon: "user", title: "Users", type: "link" }] : []),
      ],
    },
    {
      title: "Miscellaneous",
      Items: [
        { id: 32, path: `${process.env.PUBLIC_URL}/knowledgebase/knowledgebase`, icon: "knowledgebase", type: "link", active: false, title: "Latest Updates" },
        {
          title: "Faq",
          icon: "faq",
          id: 29,
          type: "sub",
          active: false,
          children: [
            { path: `${process.env.PUBLIC_URL}/faq/faq`, title: "Faq", type: "link" },
            ...(checkPermission(userPermissions, 'faq', 'view') ? [{ path: `${process.env.PUBLIC_URL}/faq/addfaq`, title: "Add Faq", type: "link" }] : []),
          ],
        },
        ...(checkPermission(userPermissions, 'elearning', 'view') ? [{
          title: "E-Learning",
          icon: "learning",
          id: 29,
          type: "sub",
          active: false,
          children: [
            { path: `${process.env.PUBLIC_URL}/elearning/addcontent`, title: "Add Content", type: "link" },
            { path: `${process.env.PUBLIC_URL}/elearning/data`, title: "Learning List", type: "link" },
          ],
        }] : []),
        {
          title: "Learning",
          icon: "learning",
          id: 29,
          type: "sub",
          active: false,
          children: [
            { path: `${process.env.PUBLIC_URL}/learning/learninglist`, title: "Learning List", type: "link" },
            { path: `${process.env.PUBLIC_URL}/learning/detailcourse`, title: "Detailed Course", type: "link" },
          ],
        },
        { id: 32, path: `${process.env.PUBLIC_URL}/knowledgebase/knowledgebase`, icon: "knowledgebase", type: "link", active: false, title: "Knowledgebase" },
        ...(checkPermission(userPermissions, 'supportticket', 'view') ? [{ id: 33, path: `${process.env.PUBLIC_URL}/supportticket/supportticket`, icon: "support-tickets", type: "link", active: false, title: "SupportTicket" }] : []),
      ],
    },
  ];
}

export { MenuList };
