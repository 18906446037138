import GroupChatContainer from "../../../../Component/Application/Chat/GroupChat/GroupChat"

const GroupChat = () => {
  return (
    <div className='page-body'>
      <GroupChatContainer />
    </div>
  )
}

export default GroupChat