import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Label, Input, Card, CardBody } from 'reactstrap';
import { toast } from 'react-toastify';
import Breadcrumbs from '../../../CommonElements/Breadcrumbs/Breadcrumbs';
import CommonCardHeader from '../../../CommonElements/CommonCardHeader/CommonCardHeader';
import { Btn } from '../../../AbstractElements';

const SupportTicketForm = () => {
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    category: '',
    priority: '',
    email: '',
    attachment: null, // New state for attachment
  });
  const [loading, setLoading] = useState(false);
  const [distributers, setDistributers] = useState([]);

  useEffect(() => {
    fetchDistributers();
  }, []);

  const fetchDistributers = async () => {
    try {
      const response = await fetch('https://mobileapp.hemexhealth.org/get_distributers');
      const data = await response.json();
      setDistributers(data.modules);
    } catch (error) {
      console.error('Error fetching distributers:', error);
    }
  };

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    // If it's a file input, set the attachment state
    if (files) {
      setFormData({ ...formData, [name]: files[0] });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    const formDataWithAttachment = new FormData();
    // Append other form data fields
    formDataWithAttachment.append('title', formData.title);
    formDataWithAttachment.append('description', formData.description);
    formDataWithAttachment.append('category', formData.category);
    formDataWithAttachment.append('priority', formData.priority);
    formDataWithAttachment.append('email', formData.email);
    // Append attachment file
    formDataWithAttachment.append('attachment', formData.attachment);

    try {
      const response = await fetch('https://mobileapp.hemexhealth.org/requestsupport', {
        method: 'POST',
        body: formDataWithAttachment, // Use FormData for file upload
      });

      const data = await response.json();

      if (response.ok) {
        toast.success('Support ticket submitted successfully');
        setFormData({
          title: '',
          description: '',
          category: '',
          priority: '',
          email: '',
          attachment: null, // Reset attachment state
        });
      } else {
        toast.error(data.message || 'Submission failed');
      }
    } catch (error) {
      console.error('Error submitting support ticket:', error);
      toast.error('An error occurred while submitting the support ticket');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className='page-body'>
      <Breadcrumbs mainTitle={'Support Ticket'} parent={'Support Ticket'} />
      <Container fluid>
        <Row>
          <Col sm='12'>
            <Card>
              <CommonCardHeader title={'Support Ticket'} />
              <CardBody>
                <div className=''>
                  <Form onSubmit={handleSubmit}>
                    <Row className='g-3'>
                      <Col md='6'>
                        <Label for='title'>Title</Label>
                        <Input
                          type='text'
                          id='title'
                          name='title'
                          placeholder='Title'
                          value={formData.title}
                          onChange={handleChange}
                          required
                        />
                      </Col>
                      <Col md='6'>
                        <Label for='description'>Description</Label>
                        <Input
                          type='text'
                          id='description'
                          name='description'
                          placeholder='Description'
                          value={formData.description}
                          onChange={handleChange}
                          required
                        />
                      </Col>
                      <Col md='6'>
                        <Label for='category'>Category</Label>
                        <Input
                          type='text'
                          id='category'
                          name='category'
                          placeholder='Category'
                          value={formData.category}
                          onChange={handleChange}
                          required
                        />
                      </Col>
                      <Col md='6'>
                        <Label for='priority'>Priority</Label>
                        <Input
                          type='text'
                          id='priority'
                          name='priority'
                          placeholder='Priority'
                          value={formData.priority}
                          onChange={handleChange}
                          required
                        />
                      </Col>
                      <Col md='6'>
                        <Label for='email'>User</Label>
                        <Input
                          type='select'
                          id='email'
                          name='email'
                          bsSize='sm'
                          value={formData.email}
                          onChange={handleChange}
                          required
                        >
                          <option value=''>Select User</option>
                          {distributers.map((distributer) => (
                            <option key={distributer._id} value={distributer.email}>
                              {distributer.name} - {distributer.email}
                            </option>
                          ))}
                        </Input>
                      </Col>
                      <Col md='6'>
                        <Label for='attachment'>Attachment</Label>
                        <Input
                          type='file'
                          id='attachment'
                          name='attachment'
                          onChange={handleChange}
                          accept='.pdf,.doc,.docx,image/*'
                        />
                      </Col>
                      <Col xs='12'>
                        <Btn color='primary' type='submit' disabled={loading}>
                          {loading ? 'Processing...' : 'Submit'}
                        </Btn>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default SupportTicketForm;
