import {
  Activity,
  AlertTriangle,
  Award,
  Bell,
  DollarSign,
  GitHub,
  Headphones,
  Heart,
  Link,
  Mail,
  Music,
  Settings,
} from "react-feather";

export const badgeData = [
  {
    text: "Use the",
    code: ".badge ",
  },
  {
    text: "utility class to make more badges.",
  },
];

export const badgesContext = [
  {
    text: "primary",
  },
  {
    text: "secondary",
  },
  {
    text: "success",
  },
  {
    text: "info",
  },
  {
    text: "warning",
    className: "text-dark",
  },
  {
    text: "danger",
  },
  {
    text: "light",
    className: "text-dark",
  },
  {
    text: "dark",
    className: "tag-pills-sm-mb",
  },
];

export const pillsData = [
  { text: "Use the", code: ".rounded-pill" },
  {
    text: " utility class to make badges more rounded with a larger ",
    code: "border-radius.",
  },
];

export const pillsContext = [
  {
    text: "primary",
  },
  {
    text: "secondary",
  },
  {
    text: "success",
  },
  {
    text: "info",
  },
  {
    text: "warning",
    className: "text-dark",
  },
  {
    text: "danger",
  },
  {
    text: "light",
    className: "text-dark",
  },
  {
    text: "dark",
    className: "tag-pills-sm-mb",
  },
];

export const numberData = [
  {
    text: "Use the",
    code: ".badge",
  },
  {
    text: " utility class to make number of more badges.",
  },
];

export const numberContext = [
  {
    text: "1",
    className: "badge-primary",
  },
  {
    text: "2",
    className: "badge-secondary",
  },
  {
    text: "3",
    className: "badge-success",
  },
  {
    text: "4",
    className: "badge-info",
  },
  {
    text: "5",
    className: "badge-warning text-dark",
  },
  {
    text: "6",
    className: "badge-danger",
  },
  {
    text: "7",
    className: "badge-light text-dark",
  },
  {
    text: "8",
    className: "badge-dark",
  },
];

export const roundedData = [
  {
    text: "Use the",
    code: ".rounded-circle",
  },
  {
    text: " utility class to make badges more rounded with a larger",
    code: "border-radius.",
  },
];

export const roundedContext = [
  {
    text: "1",
    className: "rounded-circle badge-p-space badge-primary",
  },
  {
    text: "2",
    className: "rounded-circle badge-p-space badge-secondary",
  },
  {
    text: "3",
    className: "rounded-circle badge-p-space badge-success",
  },
  {
    text: "4",
    className: "rounded-circle badge-p-space badge-info",
  },
  {
    text: "5",
    className: "rounded-circle badge-p-space badge-warning text-dark",
  },
  {
    text: "6",
    className: "rounded-circle badge-p-space badge-danger",
  },
  {
    text: "7",
    className: "rounded-circle badge-p-space badge-light text-dark",
  },
  {
    text: "8",
    className: "rounded-circle badge-p-space badge-dark",
  },
];

export const iconData = [
  {
    text: "Use the",
    code: ".badge ",
  },
  {
    text: " utility class to make more icons.",
  },
];

export const iconContext = [
  {
    className: "b-ln-height badge-primary",
    icon: <DollarSign />,
  },
  {
    className: "b-ln-height badge-secondary",
    icon: <Headphones />,
  },
  {
    className: "b-ln-height badge-success",
    icon: <Link />,
  },
  {
    className: "b-ln-height badge-info",
    icon: <GitHub />,
  },
  {
    className: "b-ln-height badge-warning text-dark",
    icon: <Award />,
  },
  {
    className: "b-ln-height badge-danger",
    icon: <Activity />,
  },
  {
    className: "b-ln-height badge-light text-dark",
    icon: <Heart />,
  },
  {
    className: "b-ln-height badge-dark",
    icon: <Mail />,
  },
];

export const roundedPillData = [
  {
    text: "Use the",
    code: ".rounded-pill",
  },
  {
    text: " utility class to make icons badges more rounded with a larger ",
    code: "border-radius.",
  },
];

export const roundedPillContext = [
  {
    className: "rounded-circle p-2 badge-primary",
    icon: <DollarSign />,
  },
  {
    className: "rounded-circle p-2 badge-secondary",
    icon: <Headphones />,
  },
  {
    className: "rounded-circle p-2 badge-success",
    icon: <Link />,
  },
  {
    className: "rounded-circle p-2 badge-info",
    icon: <GitHub />,
  },
  {
    className: "rounded-circle p-2 badge-warning text-dark",
    icon: <Award />,
  },
  {
    className: "rounded-circle p-2 badge-danger",
    icon: <Activity />,
  },
  {
    className: "rounded-circle p-2 badge-light text-dark",
    icon: <Heart />,
  },
  {
    className: "rounded-circle p-2 badge-dark",
    icon: <Mail />,
  },
];

export const badgeExData = [
  {
    text: "All html headings,",
    code: "<h1>",
  },
  { text: "through ", code: "<h6>" },
  {
    text: ", are available in ",
    code: ".badge ",
  },
  {
    text: "tags.",
  },
];

export const badgeButtonData = [
  {
    code: ".badge",
  },
  {
    text: " can be used as part of ",
    code: ".btn",
  },
  {
    text: " to provide a icons.",
  },
];

export const badgeButtonList = [
  {
    className: "secondary",
    title: "Notifications",
    icon: <Bell />,
  },
  {
    className: "success",
    title: "Update available",
    icon: <Settings />,
  },
  {
    className: "info",
    title: "Playing Now",
    icon: <Music />,
  },
  {
    className: "warning",
    title: "1.2 GB Used",
    icon: <AlertTriangle />,
  },
];
