import ProjectCreateContainer from "../../../../Component/Application/Project/ProjectCreate/ProjectCreate"

const ProjectCreate = () => {
  return (
    <div className='page-body'>
      <ProjectCreateContainer />
    </div>
  )
}

export default ProjectCreate