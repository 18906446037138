import PopoverContainer from "../../../Component/Ui-Kits/Popover/Popover"

const Popover = () => {
  return (
    <div className='page-body'>
      <PopoverContainer />
    </div>
  )
}

export default Popover