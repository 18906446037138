import { Container, Row } from 'reactstrap'
import BasicForm from '../Forms/FormsControl/BaseInput/BasicForm/BasicForm'
import SelectSizing from '../Forms/FormsControl/BaseInput/SelectSizing/SelectSizing'
import FileInput from '../Forms/FormsControl/BaseInput/FileInput/FileInput'
import BasicHTMLInputControl from '../Forms/FormsControl/BaseInput/BasicHTMLInputControl/BasicHTMLInputControl'
import Breadcrumbs from '../../CommonElements/Breadcrumbs/Breadcrumbs'
import {  FormsControl } from '../../utils/Constant'
import BaseInput from '../../Pages/Forms/FormsControl/BaseInput/BaseInput'
import { Col, Form, Input, Label } from 'reactstrap'
import { Btn } from '../../AbstractElements'
import { Card, CardBody } from "reactstrap";
import CommonCardHeader from '../../CommonElements/CommonCardHeader/CommonCardHeader'
import React, { useState } from 'react';
import { toast } from "react-toastify";

const Addcontent = () => {
  const [formData, setFormData] = useState({
    title: '',
    category: 'General',
    description: '',
    date: '2024-03-01',
    status: 'Active',
    video: null,
    language:"English",
    thumbnail: null,

  });
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (e.target.name === 'video') {
      setFormData({ ...formData, video: file });
    } else if (e.target.name === 'thumbnail') {
      setFormData({ ...formData, thumbnail: file });
    }
  };
  const handleSubmit = async (event) => {
    toast.warning('Processing');

    event.preventDefault();
    setLoading(true);
    try {
      const formDataToSend = new FormData();
      formDataToSend.append('title', formData.title);
      formDataToSend.append('category', formData.category);
      formDataToSend.append('description', formData.description);
      formDataToSend.append('date', formData.date);
      formDataToSend.append('status', formData.status);
      formDataToSend.append('video', formData.video);
      formDataToSend.append('thumbnail', formData.thumbnail);
      formDataToSend.append('language', formData.language);

      const response = await fetch('https://mobileapp.hemexhealth.org/store/module', {
        method: 'POST',
        body: formDataToSend,
      });

      const data = await response.json();
      console.log(data);
      toast.success('Data Saved Properly');
      setFormData({
        title: '',
        category: 'General',
        description: '',
        date: '2024-03-01',
        status: 'Active',
        video: '',
        language: 'English',
        thumbnail: null,
      });
    } catch (error) {
      console.error('Error uploading module:', error);
      toast.error('An error occurred while uploading the module.');
    } finally {
      setLoading(false);
    }
  };

  
  return (
    <>
        <div className='page-body'>

      <Breadcrumbs mainTitle={"E-Learning/Training Module"} parent={"ELearning"} />
      <Container fluid>
      <Row>
      <Col sm="12">
      <Card>
        <CommonCardHeader title={"ELearning"} />
        <CardBody>
          <div className="">
     
          <Form onSubmit={handleSubmit}>
      <Row className='g-3'>
        <Col md="12">
          <Label for="title">Title</Label>
          <Input type="text" id="title" name="title" placeholder="Title" value={formData.title} onChange={handleChange} required />
        </Col>
        <Col md="12">
          <Label for="category">Select Category</Label>
          <Input type="select" id="category" name="category" bsSize='sm' value={formData.category} onChange={handleChange}>
            <option value={"Troubleshoot"}>{"Troubleshoot"}</option>
            <option value={"General"}>{"General"}</option>
          </Input>
        </Col>
        <Col md="12">
          <Label for="description">Description</Label>
          <Input type="textarea" id="description" name="description" placeholder="Description" value={formData.description} onChange={handleChange} required />
        </Col>
        <Col md="12">
          <Label for="date">Date</Label>
          <Input className="digits" type="date" id="date" name="date" value={formData.date} onChange={handleChange} required />
        </Col>
        <Col md="12">
          <Label for="status">Language</Label>
          <Input type="select" id="language" name="language" bsSize='sm' value={formData.language} onChange={handleChange}>
            <option value={"English"}>{"English"}</option>
            <option value={"Hindi"}>{"Hindi"}</option>
            <option value={"Odiya"}>{"Odiya"}</option>
       
          </Input>
        </Col>

        <Col md="12">
          <Label for="status">Status</Label>
          <Input type="select" id="status" name="status" bsSize='sm' value={formData.status} onChange={handleChange}>
            <option value={"Active"}>{"Active"}</option>
            <option value={"InActive"}>{"InActive"}</option>
          </Input>
        </Col>
        <Col md="12">
          <Label for="file">File</Label>
          
          <Input type="file" id="video" name="video" accept="video/*" onChange={handleFileChange} required />
        </Col>
        <Col md='12'>
                        <Label for='thumbnail'>Thumbnail</Label>
                        <Input
                          type='file'
                          id='thumbnail'
                          name='thumbnail'
                          accept='image/*'
                          onChange={handleFileChange}
                          required
                        />
                      </Col>
        <Col xs="12">
        <Btn color='primary' type='submit' disabled={loading}>
                            {loading ? 'Processing...' : 'Add Data'}
                          </Btn>
        </Col>
      </Row>
    </Form>
     </div>
        </CardBody>
      </Card>
    
    </Col>
    </Row>
      </Container>
      </div>
    </>
  )
}

export default Addcontent