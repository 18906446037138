import RegisterWithImageTwoContainer from "../../../../Component/OtherPages/Authentication/RegisterWithImageTwo/RegisterWithImageTwo"

const RegisterWithImageTwo = () => {
  return (
    <div className='page-body'>
      <RegisterWithImageTwoContainer />
    </div>
  )
}

export default RegisterWithImageTwo