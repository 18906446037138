import FlatStyleContainer from "../../../Component/Buttons/FlatStyle/FlatStyle"

const FlatStyle = () => {
  return (
    <div className='page-body'>
      <FlatStyleContainer />
    </div>
  )
}

export default FlatStyle