import PaymentDetailsContainer from "../../../../Component/Application/Ecommerce/PaymentDetails/PaymentDetails"

const PaymentDetails = () => {
  return (
    <div className='page-body'>
      <PaymentDetailsContainer />
    </div>
  )
}

export default PaymentDetails