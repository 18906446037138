import React, { useMemo, useState, useEffect } from 'react';
import { Card, CardBody, CardHeader, Col, Input, Label, Modal, ModalBody, FormGroup, ModalHeader } from 'reactstrap';
import DataTable, { TableColumn } from "react-data-table-component";
import { Btn, H4 } from '../../AbstractElements';

const SubAdminList = () => {
    const [filterText, setFilterText] = useState("");
    const [tableData, setTableData] = useState<any[]>([]); 
    const [selectedUser, setSelectedUser] = useState<any>(null);
    const [editModal, setEditModal] = useState(false);
    const [permissions, setPermissions] = useState<Record<string, boolean>>({});
    const [status, setStatus] = useState<string>("");

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const response = await fetch('https://mobileapp.hemexhealth.org/get_subAdmin');
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setTableData(data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const filteredItems = tableData.filter(item => {
        return (
            item.first_name.toLowerCase().includes(filterText.toLowerCase()) ||
            item.email.toLowerCase().includes(filterText.toLowerCase())
        );
    });

    const subHeaderComponentMemo = useMemo(() => {
        return (
            <div id="API-1_filter" className="dataTables_filter d-flex align-items-center">
                <Label className="me-1">{"Search"}:</Label>
                <Input onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFilterText(e.target.value)} type="search" value={filterText} />
            </div>
        );
    }, [filterText]);
    
    const columns: TableColumn<any>[] = [
        {
            name: 'Sl No',
            cell: (row: any, index: number) => index + 1,
            sortable: true,
        },
        {
            name: 'First Name',
            selector: (row) => row.first_name,
            sortable: true,
        },
        {
            name: 'Last Name',
            selector: (row) => row.last_name,
            sortable: true,
        },
        {
            name: 'Email',
            selector: (row) => row.email,
            sortable: true,
        },
        {
            name: 'Phone',
            selector: (row) => row.phone,
            sortable: true,
        },
        {
            name: 'Permissions',
            cell: (row: any) => (
                <div>
                    {Object.entries(row.permissions).map(([key, value]) => (
                        <FormGroup check key={key} style={{marginTop:"5px"}}>
                            <Label check>
                                <Input
                                    type='checkbox'
                                    checked={typeof value === 'boolean' ? value : false}
                                    onChange={(e) => handlePermissionChange(key, e.target.checked)}
                                />
                                {' '}
                                {key}
                            </Label>
                        </FormGroup>
                    ))}
                </div>
            ),
        },
        {
            name: 'Status',
            cell: (row: any) => (
                <Btn className="btn-pill btn-sm" color={row.status === "Active" ? "success" : "danger"} onClick={() => handleStatusChange(row)}>
                    {row.status}
                </Btn>
            ),
            sortable: true,
        },
        {
            name: 'Actions',
            cell: (row: any) => (
                <Btn className="btn-pill btn-sm" color="primary" onClick={() => handleEdit(row)}>
                    Edit
                </Btn>
            ),
            button: true,
        },
    ];

    const handleEdit = (user: any) => {
        setSelectedUser(user);
        setPermissions(user.permissions);
        setEditModal(true);
    };

    const handlePermissionChange = (permission: string, value: boolean) => {
        setPermissions((prevPermissions: Record<string, boolean>) => ({
            ...prevPermissions,
            [permission]: value
        }));
    };

    const handleStatusChange = async (user: any) => {
        try {
            const newStatus = user.status === "Active" ? "Inactive" : "Active";
            const response = await fetch(`https://mobileapp.hemexhealth.org/update_status/${user.id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ status: newStatus }),
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            setStatus(newStatus);
        } catch (error) {
            console.error('Error updating status:', error);
        }
    };

    const handleSaveChanges = async (statusToUpdate: string) => {
        try {
            const response = await fetch(`https://mobileapp.hemexhealth.org/update_subAdmin`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email: selectedUser.email,
                    status: statusToUpdate,
                    permissions,
                }),
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            setEditModal(false);
            fetchData(); // Refresh data after successful update
        } catch (error) {
            console.error('Error updating sub-admin:', error);
        }
    };

    const renderEditModal = () => (
        <Modal isOpen={editModal} toggle={() => setEditModal(false)}>
            <ModalHeader>
                Sub Admin Permissions
            </ModalHeader>
            <ModalBody>
                {selectedUser && (
                    <div>
                        <h4>Edit Sub Admin: {selectedUser.first_name}</h4>
                        <p>Email: {selectedUser.email}</p>
                        <h5>Permissions:</h5>
                        {Object.entries(permissions).map(([key, value]) => (
                            <FormGroup check key={key} style={{marginTop:"5px"}}>
                                <Label check>
                                    <Input
                                        type='checkbox'
                                        checked={value}
                                        onChange={(e) => handlePermissionChange(key, e.target.checked)}
                                    />
                                    {' '}
                                    {key}
                                </Label>
                            </FormGroup>
                        ))}
                        <FormGroup>
                        <Label for="statusSelect">Status:</Label>
                        <Input type="select" name="statusSelect" id="statusSelect" value={status} onChange={(e) => setStatus(e.target.value)}>
                            <option value="Active">Active</option>
                            <option value="Inactive">Inactive</option>
                        </Input>
                    </FormGroup>
                    </div>
                )}
            </ModalBody>
            <div className="modal-footer">
                <Btn color="primary" onClick={() => handleSaveChanges(status)}>Save</Btn>
                <Btn color="secondary" onClick={() => setEditModal(false)}>Cancel</Btn>
            </div>
        </Modal>
    );

    return (
        <div className='page-body'>
            <Col sm="12">
                <Card>
                    <CardHeader>
                        <H4 className="mb-3">Sub-Admin List</H4>
                    </CardHeader>
                    <CardBody>
                        <div className="table-responsive">
                            <div id="API-1" className="dataTables_wrapper">
                                <DataTable data={filteredItems} columns={columns} striped={true} pagination className="display" subHeader subHeaderComponent={subHeaderComponentMemo} />
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </Col>
            {renderEditModal()}
        </div>
    );
}

export default SubAdminList;
