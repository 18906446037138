import { Business, CreatedByMe, Favourites, Holidays, Important, MyBookmark, Newsletter, Notification, Orgenization, SharedWithMe } from "../../../utils/Constant";

 export const sideBarData = [
    { value: "1", tittle: CreatedByMe },
    { value: "2", tittle: Favourites },
    { value: "3", tittle: SharedWithMe },
    { value: "4", tittle: MyBookmark },
  ];
  export const sideBarData2 = [
    { value: "5", tittle: Notification },
    { value: "6", tittle: Newsletter },
    { value: "7", tittle: Business },
    { value: "8", tittle: Holidays },
    { value: "9", tittle: Important },
    { value: "10", tittle: Orgenization },
  ];
  
  export const bookMarkData = [
    {
      id: 1,
      fillStar: false,
      image: "lightgallry/01.jpg",
      title: "Admin Template",
      website_url: "http://admin.pixelstrap.com/Xolo/ltr/landing-page.html",
      desc: "Xolo is beautifully crafted, clean and modern designed admin theme with 6 different demos and light - dark versions.",
      collection: "General",
    },
    {
      id: 2,
      fillStar: false,
      image: "lightgallry/02.jpg",
      title: "Universal Template",
      website_url: "https://angular.pixelstrap.com/universal/landing",
      desc: "Universal is beautifully crafted, clean and modern designed admin theme",
      collection: "General",
    },
    {
      id: 3,
      fillStar: false,
      image: "lightgallry/03.jpg",
      title: "Angular Theme",
      website_url: "https://angular.pixelstrap.com/Xolo/landing",
      desc: "Xolo is beautifully crafted, clean and modern designed admin theme",
      collection: "Fs",
    },
    {
      id: 4,
      fillStar: false,
      image: "lightgallry/04.jpg",
      title: "Multikart Admin",
      website_url: "http://themes.pixelstrap.com/multikart/back-end/index.html",
      desc: "Multikart Admin is modern designed admin theme",
      collection: "General",
    },
    {
      id: 5,
      fillStar: false,
      image: "lightgallry/05.jpg",
      title: "Ecommerece theme",
      website_url: "http://themes.pixelstrap.com/multikart",
      desc: "Multikart HTML template is an apparently simple but highly functional tempalate designed for creating a flourisahing online business.",
      collection: "General",
    },
    {
      id: 6,
      fillStar: false,
      image: "lightgallry/06.jpg",
      title: "Tovo app landing page",
      website_url: "http://vue.pixelstrap.com/tovo/home-one",
      desc: "Amazing Landing Page With Easy Customization",
      collection: "Fs",
    },
  ];
  