import BasicTableContainer from "../../../../Component/Tables/ReactstrapTable/BasicTable/BasicTable"

const BasicTable = () => {
  return (
    <div className='page-body'>
      <BasicTableContainer />
    </div>
  )
}

export default BasicTable