export const barRatingData = [
  {
    text: "Rating is displayed using ",
    code: "step={*}",
  },
  {
    text: " attr in Rating tag",
  },
];

export const movingRatingDta = [
  {
    text: "Movie rating is displayed using ",
    code: "step={4}",
  },
  {
    text: " in react-rating package",
  },
];

export const squareRatingData = [
  {
    text: "Square rating is displayed using",
    code: " emptySymbol & fullSymbol ",
  },
];

export const pillRatingData = [
  {
    text: "Pill rating is displayed using ",
    code: ".pill-rating",
  },
  {
    text: " class",
  },
];

export const reverseRatingData = [
  {
    text: "Reversed rating is displayed using ",
    code: 'direction="rtl"',
  },
  {
    text: " Attribute in Rating.",
  },
];

export const starRatingData = [
  {
    text: "Star rating is displayed using ",
    code: ".fa-star-o & fa-star",
  },
  {
    text: " class in rating symbol",
  },
];

export const halfStarRatingData = [
  {
    text: "Star rating is displayed using ",
    code: ".fa-star-o & fa-star",
  },
  {
    text: " class in rating symbol",
  },
];

export const thumbUpDownData = [
  {
    text: "Thumb rating is displayed using ",
    code: ".fa-thumbs-up & .fa-thumbs-down",
  },
  {
    text: " class in rating symbol",
  },
];

export const heartRatingData = [
  {
    text: "Heart rating is displayed using ",
    code: ".fa-heart-o & .fa-heart",
  },
  {
    text: " class in rating symbol",
  },
];
