import Error500Container from "../../../../Component/OtherPages/Error/Error500/Error500"

const Error500 = () => {
  return (
    <div className='page-body'>
      <Error500Container />
    </div>
  )
}

export default Error500