import CheckoutContainer from "../../../../Component/Application/Ecommerce/Checkout/Checkout"

const Checkout = () => {
  return (
    <div className='page-body'>
      <CheckoutContainer />
    </div>
  )
}

export default Checkout