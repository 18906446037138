export const gridData = [
  {
    text: "Bootstrap grid system allow all six breakpoints, and any breakpoints you can customize.",
  },
];

export const gridOptionHeader = [
  {
    title: "Small",
    small: "≥576px",
  },
  {
    title: "Medium",
    small: "≥768px",
  },
  {
    title: "Large",
    small: "≥992px",
  },
  {
    title: "Extra large",
    small: "≥1200px",
  },
  {
    title: "Extra extra large",
    small: "≥1400px",
    className: "digits",
  },
];

export const containerWidth = [
  "None (auto)",
  "540px",
  "720px",
  "960px",
  "1140px",
  "1320px",
];

export const classPrefixData = [
  ".col-",
  ".col-sm-",
  ".col-md-",
  ".col-lg-",
  ".col-xl-",
  ".col-xxl-",
];

export const restGrid = [
  {
    title: "# of columns",
    td: "12",
  },
  {
    title: "Gutter width",
    td: "1.5rem (.75rem on left and right)",
  },
  {
    title: "Nestable",
    td: "Yes",
  },
  {
    title: "Offsets",
    td: "Yes",
  },
  {
    title: "Column ordering",
    td: "Yes",
  },
];

export const columnData = [
  { text: "You may use predefined grid classes. Using ", code: ".col-md-*" },
  { text: "you can set the grid system." },
];

export const gridColumnDetails = [
  {
    size: "2",
    item: "col-md-2",
  },
  {
    size: "2",
    item: "col-md-2",
  },
  {
    size: "3",
    item: "col-md-3",
  },
  {
    size: "4",
    item: "col-md-4",
  },
  {
    size: "5",
    item: "col-md-5",
  },
  {
    size: "7",
    item: "col-md-7",
  },
  {
    size: "6",
    item: "col-md-6",
  },
  {
    size: "6",
    item: "col-md-6",
  },
  {
    size: "8",
    item: "col-md-8",
  },
  {
    size: "4",
    item: "col-md-4",
  },
  {
    size: "9",
    item: "col-md-9",
  },
  {
    size: "3",
    item: "col-md-3",
  },
  {
    size: "10",
    item: "col-md-10",
  },
  {
    size: "2",
    item: "col-md-2",
  },
  {
    size: "12",
    item: "col-md-12",
  },
];

export const alignmentData = [
  {
    text: "You can use the ",
    code: "justify-items-*",
  },
  {
    text: " class to set the Vertical alignment.",
  },
];

export const verticalAlignment = ["align-items-center", "align-items-end"];

export const horizontalAlignment = [
  "justify-content-center",
  "justify-content-end",
];

export const nestingData = [
  {
    text: "To nest your content with the default grid, add a new",
    code: ".row",
  },
  {
    text: " and set of ",
    code: ".col-sm-*",
  },
  {
    text: " columns within an existing ",
    code: ".col-sm-*",
  },
  {
    text: " column.",
  },
];

export const orderData = [
  {
    text: "Using ",
    code: ".order ",
  },
  {
    text: "class, you can set the order position.",
  },
];

export const orderList = [
  {
    extraSmallSize: "5",
    item: "Second Item",
    order: "order-first",
  },
  {
    extraSmallSize: "4",
    item: "Third Item",
    order: "order-last",
  },
  {
    extraSmallSize: "3",
    item: "Fourth Item",
    order: "order-2",
  },
  {
    extraSmallSize: "4",
    smallSize: "2",
    item: "fifth Item",
    order: "order-5",
  },
  {
    extraSmallSize: "4",
    smallSize: "6",
    item: "sixth Item",
    order: "order-4",
  },
];

export const offsetData = [
  {
    text: "You can offset the grid column using ",
    code: ".offset-*",
  },
  {
    text: " grid class.",
  },
];

export const offsetList = [
  {
    extraSmallSize: "4",
    smallSize: "2",
    className: "offset-sm-2 offset-1",
    item: "col-2 offset-2",
  },
  {
    extraSmallSize: "5",
    smallSize: "4",
    className: "offset-2",
    item: "col-4 offset-2",
  },
  {
    extraSmallSize: "4",
    smallSize: "3",
    className: "offset-sm-2 offset-1",
    item: "col-3 offset-2",
  },
];
