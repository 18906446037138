import React, { useState, useEffect } from 'react';
import { Col, Row } from 'reactstrap';
import { H5, H6 } from '../../../../AbstractElements';
import {  QuickQuestions } from '../../../../utils/Constant';
import { Fragment } from 'react';
import AccordionCard from './AccordionCard';
import FaqRightSidebar from './FaqRightSidebar';
import Articles from '../Articles';
import Knowledgebasefaq from '../Knowledgebase';

const Questions = () => {
  const [faqs, setFaqs] = useState<any[]>([]);

  useEffect(() => {
    fetchFaqs();
  }, []);

 
  const fetchFaqs = async () => {
    try {
      const response = await fetch('https://mobileapp.hemexhealth.org/faqs', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ category: 'Faq' }) // JSON body with category "Faq"
      });
      
      if (!response.ok) {
        throw new Error('Failed to add FAQ');
      }
      const data = await response.json();
    
      setFaqs(data.faqs);
    } catch (error) {
      console.error('Error adding FAQ:', error);
    }
  };

  return (
    <Col lg="12">
      <div className="header-faq">
        <H5 className="mb-0">{QuickQuestions}</H5>
      </div>
      <Row className="default-according style-1 faq-accordion">
        <Col lg="12" xl="12" md="12" className='xl-60'>
        <Col xl="8" lg="6" md="7" className="xl-60">
        {faqs.map((faq, index) => (
            <Fragment key={index}>
              
              <AccordionCard item={faq} />
            </Fragment>
          ))}
        </Col>
        <Articles/>
        <Knowledgebasefaq/>
        </Col>
        <FaqRightSidebar />
      </Row>
    </Col>
  );
};

export default Questions;
