import MaintenanceContainer from "../../../../Component/OtherPages/Authentication/Maintenance/Maintenance"

const Maintenance = () => {
  return (
    <div className='page-body'>
      <MaintenanceContainer />
    </div>
  )
}

export default Maintenance