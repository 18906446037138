import { Link } from "react-router-dom";
import { Card, CardBody, Col, Row } from "reactstrap";
import { FeatherIcons, H3 } from "../../../../AbstractElements";
import { totalRevenueData } from "../../../../Data/Dashboard/Default";
import { ViewReport } from "../../../../utils/Constant";
import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

const TotalRevenue = () => {
  const [totalUsers, setTotalUsers] = useState(null);
  const [otherData, setOtherData] = useState([]);

  useEffect(() => {
    
    // Fetch total users data from the API
    // fetch('https://mobileapp.hemexhealth.org/distributer_count')
    //   .then(response => response.json())
    //   .then(data => {
    //     // Assuming the response data contains the total user count
    //     // Update the state with the fetched total users count
    //     setTotalUsers(data.count);
    //   })
    //   .catch(error => {
    //     console.error('Error fetching total users data:', error);
    //   });

  }, []); // Empty dependency array ensures the effect runs only once on component mount

  return (
    <Col xl="6" md="6" className="box-col-6 total-revenue-total-order">
      <Row>
        {totalRevenueData.map((data, i) => (
          <Col xl="12" key={i}>
            <Card>
              <CardBody>
                <div className="total-revenue mb-2">
                  <span>{data.title}</span>
                  <Link to={`${process.env.PUBLIC_URL}/dashboard/default`}>{ViewReport}</Link>
                </div>
                <H3 className="f-w-600">{i === 0 ? (totalUsers !== null ? totalUsers : data.amount) : ( data.amount)}</H3>
                <div className="total-chart">
                  <div className="data-grow d-flex gap-2">
                    <FeatherIcons className="font-primary" iconName={data.icon} />
                    <span className="f-w-500">{data.result}</span>
                  </div>
                  <div className={data.chartClass}>
                    <ReactApexChart options={data.chartOptions} series={data.chartOptions.series} type={data.chartType} height={120} />
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        ))}
      </Row>
    </Col>
  );
};

export default TotalRevenue;
