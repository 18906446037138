import TouchSpinContainer from "../../../../Component/Forms/FormsWidgets/TouchSpin/TouchSpin"

const TouchSpin = () => {
  return (
    <div className='page-body'>
      <TouchSpinContainer />
    </div>
  )
}

export default TouchSpin