import ContainerHideNavScroll from "../../../Component/PageLayout/HideNavScroll/HideNavScroll"

const HideNavScrollContainer = () => {
  return (
    <div className='page-body'>
      <ContainerHideNavScroll />
    </div>
  )
}

export default HideNavScrollContainer