import SamplePageContainer from "../../Component/SamplePage/SamplePage"

const SamplePage = () => {
  return (
    <div className='page-body'>
      <SamplePageContainer />
    </div>
  )
}

export default SamplePage