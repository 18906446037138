import React, { useState, useEffect } from 'react';
import { Col, Row } from 'reactstrap';
import { Fragment } from 'react';
import AccordionCard from './Questions/AccordionCard';
import { H5, H6 } from '../../../AbstractElements';

const Knowledgebasefaq = () => {
  const [faqs, setFaqs] = useState<any[]>([]);

  useEffect(() => {
    fetchFaqs();
  }, []);

 
  const fetchFaqs = async () => {
    try {
      const response = await fetch('https://mobileapp.hemexhealth.org/faqs', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ category: 'Knowledge Base' }) // JSON body with category "Faq"
      });
      
      if (!response.ok) {
        throw new Error('Failed to add FAQ');
      }
      const data = await response.json();
    
      // Set the fetched data into the faqs state
      setFaqs(data.faqs);
      // Refresh FAQs after adding a new one
      // fetchFaqs();
    } catch (error) {
      console.error('Error adding FAQ:', error);
    }
  };

  return (
    <Col xl="12" lg="12" md="12" className="xl-100 mt-5">
    <div className="header-faq">
        <H5 className="mb-0">{"Knowledge Base"}</H5>
      </div>
      <Row className="default-according style-1 faq-accordion">
        <Col xl="8" lg="6" md="7" className="xl-60">
        {faqs.map((faq, index) => (
            <Fragment key={index}>  
              
              <AccordionCard item={faq} />
            </Fragment>
          ))}
        </Col>
        {/* <FaqRightSidebar /> */}
      </Row>
    </Col>
  );
};

export default Knowledgebasefaq;
