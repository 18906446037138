import ProductsContainer from "../../../../Component/Application/Ecommerce/Products/Products"

const Products = () => {
  return (
    <div className='page-body'>
      <ProductsContainer />
    </div>
  )
}

export default Products