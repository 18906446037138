import JobApplyContainer from "../../../../Component/Miscellaneous/JobSearch/JobApply/JobApply"

const JobApply = () => {
  return (
    <div className='page-body'>
      <JobApplyContainer />
    </div>
  )
}

export default JobApply