import BaseInputContainer from "../../../../Component/Forms/FormsControl/BaseInput/BaseInput"

const BaseInput = () => {
  return (
    <div className='page-body'>
      <BaseInputContainer />
    </div>
  )
}

export default BaseInput