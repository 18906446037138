import FormWizardTwoContainer from "../../../../Component/Forms/FormsLayout/FormWizardTwo/FormWizardTwo"

const FormWizardTwo = () => {
  return (
    <div className='page-body'>
      <FormWizardTwoContainer />
    </div>
  )
}

export default FormWizardTwo