import { LI, UL } from "../../../../AbstractElements";
import { Orders, Revenue } from "../../../../utils/Constant";

const BalanceData = () => {
  return (
    <UL className="balance-data simple-list flex-row">
      
    </UL>
  );
};

export default BalanceData;
