import CreativeCardContainer from "../../../Component/BonusUi/CreativeCard/CreativeCard"

const CreativeCard = () => {
  return (
    <div className='page-body'>
      <CreativeCardContainer />
    </div>
  )
}

export default CreativeCard