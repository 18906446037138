import BreadcrumbContainer from "../../../Component/BonusUi/Breadcrumb/Breadcrumb"

const Breadcrumb = () => {
  return (
    <div className='page-body'>
      <BreadcrumbContainer />
    </div>
  )
}

export default Breadcrumb