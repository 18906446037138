import React, { useCallback, useState, useEffect } from 'react';
import DataTable, { TableColumn } from "react-data-table-component";
import { Btn, H4 } from '../../../../AbstractElements';
import { DeleteData, DeleteSupportTicket } from '../../../../utils/Constant';
import { Modal, ModalBody } from 'reactstrap'
import { Input, FormGroup, Label } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';

const TicketTable = () => {
    const [selectedRows, setSelectedRows] = useState([]);
    const [toggleDelete, setToggleDelete] = useState(false);
    const [data, setData]  = useState<any[]>([]); 

    const [selectedTicket, setSelectedTicket] = useState<any>(null);

    const [tableData, setTableData] = useState<any[]>([]); 
    const [simpleModal, setSimpleModal] = useState(false);
    const [resolveModal, setResolveModal] = useState(false);

  const toggle = () => setSimpleModal(!simpleModal);
  const [message, setMessage] = useState('');
  const [status, setStatus] = useState('');

  const toggleModal = (ticket: any) => {
      setSelectedTicket(ticket);
///      setSimpleModal(!simpleModal);
setResolveModal(!resolveModal);

  };
    // Fetch support ticket data from the server
    useEffect(() => {
      const fetchSupportTickets = async () => {
          try {
              const response = await fetch('https://mobileapp.hemexhealth.org/allusersupportrequests', {
                  method: 'POST',
                  headers: {
                      'Content-Type': 'application/json', // Assuming JSON data is being sent
                      // Add any other required headers here
                  },
                  // If you need to send any data with the request, you can specify it here
                  // body: JSON.stringify({ key: value }),
              });
              const responseData = await response.json();
              if (responseData.status === 1) {
                  setData(responseData.requests);
                  console.log(responseData.requests); // Log fetched data to verify structure
              } else {
                  console.error('Error fetching support tickets:', responseData.message);
              }
          } catch (error) {
              console.error('Error fetching support tickets:', error);
          }
      };
  
      fetchSupportTickets();
  }, []);

  const handleRowSelected= useCallback((state:any) => {
  setSelectedRows(state.selectedRows);
    }, []);

    const handleDelete = () => {
        // Implement deletion logic here
    };
    const columns: TableColumn<any>[] = [
      {
          name: 'Title',
          selector: (row) => row.title,
          sortable: true,
      },
      {
          name: 'Description',
          selector: (row) => row.description,
          sortable: true,
      },
      {
          name: 'Category',
          selector: (row) => row.category,
          sortable: true,
      },
      
      {
          name: 'Description',
          selector: (row) => row.description,
          sortable: true,
      },
      {
          name: 'priority',
          selector: (row) => row.priority,
          sortable: true,
      },
      {
          name: 'Attachment',
          cell: (row) => (
              <>
              <Btn color="secondary" onClick={toggle}>{"Open Video"}</Btn>
              
    <Modal isOpen={simpleModal} toggle={toggle} >
      <ModalBody>
        <div className="modal-toggle-wrapper">
          <H4>Lean about <strong className="txt-danger">{row.title}</strong></H4>
          <div className="modal-img">
          <img  style={{width:"100%"}} src={'https://mobileapp.hemexhealth.org/'+row.attachmentPath}  />
                  </div>
        </div>
      </ModalBody>
    </Modal>
    </>
          ),
          sortable: false,
      },
      {
        name: 'Status',
        cell: (row) => (
            <Btn className="btn-pill btn-sm" color={row.status === 'Pending' ? 'warning' : 'success'}>
                {row.status}
            </Btn>
        ),
        sortable: true,
    },
    {
        name: 'Resolve',
        cell: (row) => (
            <Btn color="primary" onClick={() => toggleModal(row)} disabled={row.status === 'Resolved'}>
                Resolve
            </Btn>
        ),
        button: true,
    }
    
  ];
  const handleResolve = async () => {
    try {
        const response = await fetch('https://mobileapp.hemexhealth.org/updateSupportTicketStatus', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                ticketId: selectedTicket._id,
                message: message,
                newStatus: status,
            }),
        });
        const responseData = await response.json();
        if (responseData.status === 'success') {
            console.log('Ticket resolved successfully');
            toast.success('Ticket resolved successfully');
            setResolveModal(false); // Close the modal after resolving
            const fetchSupportTickets = async () => {
                try {
                    const response = await fetch('https://mobileapp.hemexhealth.org/allusersupportrequests', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json', // Assuming JSON data is being sent
                        },
                        
                    });
                    const responseData = await response.json();
                    if (responseData.status === 1) {
                        setData(responseData.requests);
                        console.log(responseData.requests); // Log fetched data to verify structure
                    } else {
                        console.error('Error fetching support tickets:', responseData.message);
                    }
                } catch (error) {
                    console.error('Error fetching support tickets:', error);
                }
            };
        
            fetchSupportTickets();
        } else {
            console.error('Error resolving ticket:', responseData.message);
            // toast.error('Error resolving ticket');
        }
    } catch (error) {
        console.error('Error resolving ticket:', error);
    }
};
    return (
        <div className="table-responsive">
            {selectedRows.length !== 0 && (
                <div className={`d-flex align-items-center justify-content-between bg-light-info p-2`}>
                    <H4 className="text-muted m-0">{DeleteData}</H4>
                    <Btn color="danger" onClick={handleDelete}>{DeleteSupportTicket}</Btn>
                </div>
            )}
            <DataTable className='custom-scrollbar' columns={columns} data={data} striped={true} pagination selectableRows onSelectedRowsChange={handleRowSelected} clearSelectedRows={toggleDelete} />
            {selectedTicket && (

                // Your component code...
                
                <Modal isOpen={resolveModal} toggle={() => toggleModal(selectedTicket)}>
                    <ModalBody>
                        <div className="modal-toggle-wrapper">
                            <H4>Resolve Ticket: <strong className="txt-danger">{selectedTicket.title}</strong></H4>
                            <div>
                                <FormGroup>
                                    <Label htmlFor="message">Message:</Label>
                                    {/* Replace the standard textarea with the TextArea component from reactstrap */}
                                    <Input type="textarea" id="message" value={message} onChange={(e) => setMessage(e.target.value)} />
                                </FormGroup>
                            </div>
                            <div>
                                <FormGroup>
                                    <Label htmlFor="status">Status:</Label>
                                    {/* Replace the standard input with the Select component from reactstrap */}
                                    <Input type="select" id="status" value={status} onChange={(e) => setStatus(e.target.value)}>
                                        <option value="">Select Status</option>
                                        <option value="Pending">Pending</option>
                                        <option value="Resolved">Resolved</option>
                                        {/* Add other status options as needed */}
                                    </Input>
                                </FormGroup>
                            </div>
                            <div>
                                <Btn color="success" onClick={handleResolve}>Resolve Ticket</Btn>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
                
            )}
        </div>
    );
}

export default TicketTable;
