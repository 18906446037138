import Error403Container from "../../../../Component/OtherPages/Error/Error403/Error403"

const Error403 = () => {
  return (
    <div className='page-body'>
      <Error403Container />
    </div>
  )
}

export default Error403