import RangeSliderContainer from "../../../Component/BonusUi/RangeSlider/RangeSlider"

const RangeSlider = () => {
  return (
    <div className='page-body'>
      <RangeSliderContainer />
    </div>
  )
}

export default RangeSlider