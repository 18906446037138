import ContainerGeneral from "../../../Component/Widgets/General/ContainerGeneral"

const General = () => {
  return (
    <div className='page-body'>
      <ContainerGeneral />
    </div>
  )
}

export default General