import Error404Container from "../../../../Component/OtherPages/Error/Error404/Error404"

const Error404 = () => {
  return (
    <div className='page-body'>
      <Error404Container />
    </div>
  )
}

export default Error404