import { MenuItem } from "../../Types/Layout/Sidebar";


// Retrieve userRole and userPermissions from localStorage
const userRole: string | null = localStorage.getItem('userRole');
const userPermissionsString: string | null = localStorage.getItem('permissions');
let userPermissions: { [key: string]: boolean } = {};



// Check if user is an admin
// const isAdmin = userRole === 'Admin';
let MenuList: MenuItem[] = [];


    MenuList = [
    {
    title: "General",
    lanClass: "lan-1",
    Items: [
      { path: `${process.env.PUBLIC_URL}/`, icon: "home", title: "Dashboard", type: "link" },
      { path: `${process.env.PUBLIC_URL}/distributer/list`, icon: "user", title: "Users", type: "link" },
      { path: `${process.env.PUBLIC_URL}/`, icon: "file", title: "Devices", type: "link" },
    ],
  },
  
  {
    title: "Reports",
    lanClass: "lan-8",
    Items: [
      {
        title: "Regional",
        id: 3,
        icon: "project",
        type: "sub",
        active: false,
        children: [
          { path: `${process.env.PUBLIC_URL}/`, type: "link", title: "State-wise" },
          { path: `${process.env.PUBLIC_URL}/`, type: "link", title: "Pincode" },
        ],
      },
      
      { path: `${process.env.PUBLIC_URL}/app/contacts`, title: "Contact", icon: "contact", type: "link", id: 11, active: false },
      { path: `${process.env.PUBLIC_URL}/app/todo`, icon: "to-do", type: "link", title: "Todo" },
    
    ],
  },


  {
    title: "Miscellaneous",
    Items: [
      { id: 32, path: `${process.env.PUBLIC_URL}/knowledgebase/knowledgebase`, icon: "knowledgebase", type: "link", active: false, title: "Latest Updates" },
      {
        title: "Faq",
        icon: "faq",
        id: 29,
        type: "sub",
        active: false,
        children: [
          { path: `${process.env.PUBLIC_URL}/faq/faq`, title: "Faq", type: "link" },
          { path: `${process.env.PUBLIC_URL}/faq/addfaq`, title: "Add Faq", type: "link" },
        ],
      },
      {
        title: "E-Learning",
        icon: "learning",
        id: 29,
        type: "sub",
        active: false,
        children: [
          { path: `${process.env.PUBLIC_URL}/elearning/addcontent`, title: "Add Content", type: "link" },
          { path: `${process.env.PUBLIC_URL}/elearning/data`, title: "Learning List", type: "link" },
        ],
      },
      {
        title: "Learning",
        icon: "learning",
        id: 29,
        type: "sub",
        active: false,
        children: [
          { path: `${process.env.PUBLIC_URL}/learning/learninglist`, title: "Learning List", type: "link" },
          { path: `${process.env.PUBLIC_URL}/learning/detailcourse`, title: "Detailed Course", type: "link" },
        ],
      },
      { id: 32, path: `${process.env.PUBLIC_URL}/knowledgebase/knowledgebase`, icon: "knowledgebase", type: "link", active: false, title: "Knowledgebase" },
      { id: 33, path: `${process.env.PUBLIC_URL}/supportticket/supportticket`, icon: "support-tickets", type: "link", active: false, title: "SupportTicket" },
      { id: 34, path: `${process.env.PUBLIC_URL}/supportticket/addsupportticket`, icon: "support-tickets", type: "link", active: false, title: "Add SupportTicket" },
    ],
  },
  {
    title: "Administration",
    Items: [
      { path: `${process.env.PUBLIC_URL}/roles/add`, icon: "chat", title: "Add Role", type: "link" },
      { path: `${process.env.PUBLIC_URL}/subadmin/add`, icon: "chat", title: "Sub Admin", type: "link" },
      { path: `${process.env.PUBLIC_URL}/`, icon: "user", title: "Employees", type: "link" },
    ],
  },
];

export {MenuList};
