import { ServiceType } from "../../../Types/Application/Ecommerce/Ecommerce";

export const productSocial = [
  {
    link: "https://www.facebook.com/",
    iconName: "facebook",
  },
  {
    link: "https://accounts.google.com/",
    iconName: "google-plus",
  },
  {
    link: "https://twitter.com/",
    iconName: "twitter",
  },
  {
    link: "https://www.instagram.com/",
    iconName: "instagram",
  },
  {
    link: "https://rss.app/",
    iconName: "rss",
  },
];

export const clothsDetailsData = ["Fabric", "Video", "Details", "Brand"];

export const brandDetailsData = ["Clothing", "Bags", "Footwear", "Watches","ACCESSORIES"];

export const servicesData: ServiceType[] = [
  {
    icon: "Truck",
    title: "Free Shipping",
    subtitle: "Free Shipping World Wide",
  },
  {
    icon: "Clock",
    title: "24 X 7 Service",
    subtitle: "Online Service For New Customer",
  },
  {
    icon: "Gift",
    title: "Festival Offer",
    subtitle: "New Online Special Festival",
  },
  {
    icon: "CreditCard",
    title: "Online Payment",
    subtitle: "Contrary To Popular Belief.",
  },
];
