import TourContainer from "../../../Component/BonusUi/Tour/Tour"

const Tour = () => {
  return (
    <div className='page-body'>
      <TourContainer />
    </div>
  )
}

export default Tour