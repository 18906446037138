import OwlCarouselContainer from "../../../Component/BonusUi/OwlCarousel/OwlCarousel"

const OwlCarousel = () => {
  return (
    <div className='page-body'>
      <OwlCarouselContainer />
    </div>
  )
}

export default OwlCarousel