import ProgressContainer from "../../../Component/Ui-Kits/Progress/Progress"

const Progress = () => {
  return (
    <div className='page-body'>
      <ProgressContainer />
    </div>
  )
}

export default Progress