import React, { useMemo, useState, useEffect } from 'react';
import { Card, CardBody, CardHeader, Col, Input, Label } from 'reactstrap';
import DataTable, { TableColumn } from "react-data-table-component";
import { Btn, H4, P } from '../../AbstractElements';
import { Modal, ModalBody } from 'reactstrap'

const Elearning = () => {
    const [filterText, setFilterText] = useState("");
    const [tableData, setTableData] = useState<any[]>([]); 
    const [simpleModal, setSimpleModal] = useState(false);
  const toggle = () => setSimpleModal(!simpleModal);
  
    useEffect(() => {
        fetchData();
    }, []);
    const fetchData = async () => {
        try {
            const response = await fetch('https://mobileapp.hemexhealth.org/modules');
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setTableData(data.modules);
        } catch (error) {
            console.error('Error fetching data:', error);
            // Handle error gracefully, e.g., show error message to the user
        }
    };

    const filteredItems = tableData && tableData.filter((item) => item.title && item.title.toString().toLowerCase().includes(filterText.toLowerCase()));

    const subHeaderComponentMemo = useMemo(() => {
        return (
          <div id="API-1_filter" className="dataTables_filter d-flex align-items-center">
            <Label className="me-1">{"Search Data"}:</Label>
            <Input onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFilterText(e.target.value)} type="search" value={filterText} />
          </div>
        );
      }, [filterText]);
    
    const columns: TableColumn<any>[] = [
        {
            name: 'Title',
            selector: (row) => row.title,
            sortable: true,
        },
        {
            name: 'Category',
            selector: (row) => row.category,
            sortable: true,
        },
        {
            name: 'Language',
            selector: (row) => row.language,
            sortable: true,
        },
        
        {
            name: 'Description',
            selector: (row) => row.description,
            sortable: true,
        },
        {
            name: 'Date',
            selector: (row) => row.date,
            sortable: true,
        },
        {
            name: 'Video',
            cell: (row) => (
                <>
                <Btn color="secondary" onClick={toggle}>{"Open Video"}</Btn>
                
      <Modal isOpen={simpleModal} toggle={toggle} >
        <ModalBody>
          <div className="modal-toggle-wrapper">
            <H4>Lean about <strong className="txt-danger">{row.title}</strong></H4>
            <div className="modal-img">
            <video controls style={{width:"100%"}}>
                    <source src={row.videoPath} type="video/mp4" />
                    Your browser does not support the video tag.
                </video></div>
          </div>
        </ModalBody>
      </Modal>
      </>
            ),
            sortable: false,
        },
        {
            name: 'Status',
            selector: (row) =>                 <Btn  className="btn-pill btn-sm" color="success" onClick={toggle}>{row.status}</Btn>
            ,
            sortable: true,
        },
    ];

    return (
    <div className='page-body'>
    
        <Col sm="12">
            <Card>
                <CardHeader>
                    <H4 className="mb-3">E Learning Data</H4>
                </CardHeader>
                <CardBody>
                    {/* No addNewRow button is present in this code. You can add it if needed */}
                    <div className="table-responsive">
                        <div id="API-1" className="dataTables_wrapper">
                            <DataTable data={filteredItems} columns={columns} striped={true} pagination className="display" subHeader subHeaderComponent={subHeaderComponentMemo} />
                        </div>
                    </div>
                </CardBody>
            </Card>
        </Col>
        </div>
    );
}

export default Elearning;
