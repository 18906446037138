import CardViewContainer from "../../../../Component/Miscellaneous/JobSearch/CardView/CardView"

const CardView = () => {
  return (
    <div className='page-body'>
      <CardViewContainer />
    </div>
  )
}

export default CardView