import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Label, Input, Card, CardBody } from 'reactstrap';
import { toast } from 'react-toastify';
import Breadcrumbs from '../../CommonElements/Breadcrumbs/Breadcrumbs';
import CommonCardHeader from '../../CommonElements/CommonCardHeader/CommonCardHeader';
import { Btn } from '../../AbstractElements';

const SubAdmin = () => {
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    phone: '',
    role: 'SubAdmin',
    status: 'Active'
  });
  const [loading, setLoading] = useState(false);
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    fetchRoles();
  }, []);

  const fetchRoles = async () => {
    try {
      const response = await fetch('https://mobileapp.hemexhealth.org/api/roles');
      const data = await response.json();
      setRoles(data);
    } catch (error) {
      console.error('Error fetching roles:', error);
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
  
    try {
      const response = await fetch('https://mobileapp.hemexhealth.org/register', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...formData,
          permissions: roles.filter(role => role.roleName === formData.role)[0]?.permissions
        }),
      });
  
      const data = await response.json();
  
      if (response.ok) {
        toast.success('User registered successfully');
        setFormData({
          first_name: '',
          last_name: '',
          email: '',
          password: '',
          phone: '',
          role: '',
          status: 'Active'
        });
      } else {
        toast.error(data.message || 'Registration failed');
      }
    } catch (error) {
      console.error('Error during registration:', error);
      toast.error('An error occurred while registering the user');
    } finally {
      setLoading(false);
    }
  };
  
  return (
    <div className='page-body'>
      <Breadcrumbs mainTitle={'Sub Admin'} parent={'Sub Admin'} />
      <Container fluid>
        <Row>
          <Col sm='12'>
            <Card>
              <CommonCardHeader title={'Sub Admin'} />
              <CardBody>
                <div className=''>
                  <Form onSubmit={handleSubmit}>
                    <Row className='g-3'>
                      <Col md='6'>
                        <Label for='first_name'>First Name</Label>
                        <Input
                          type='text'
                          id='first_name'
                          name='first_name'
                          placeholder='First Name'
                          value={formData.first_name}
                          onChange={handleChange}
                          required
                        />
                      </Col>
                      <Col md='6'>
                        <Label for='last_name'>Last Name</Label>
                        <Input
                          type='text'
                          id='last_name'
                          name='last_name'
                          placeholder='Last Name'
                          value={formData.last_name}
                          onChange={handleChange}
                          required
                        />
                      </Col>
                      <Col md='6'>
                        <Label for='email'>Email</Label>
                        <Input
                          type='email'
                          id='email'
                          name='email'
                          placeholder='Email'
                          value={formData.email}
                          onChange={handleChange}
                          required
                        />
                      </Col>
                      <Col md='6'>
                        <Label for='password'>Password</Label>
                        <Input
                          type='password'
                          id='password'
                          name='password'
                          placeholder='*********'
                          value={formData.password}
                          onChange={handleChange}
                          required
                        />
                      </Col>
                      <Col md='6'>
                        <Label for='phone'>Phone</Label>
                        <Input
                          type='text'
                          id='phone'
                          name='phone'
                          placeholder='1111111111'
                          value={formData.phone}
                          onChange={handleChange}
                          required
                        />
                      </Col>
                      <Col md='6'>
                        <Label for='role'>Role</Label>
                        <Input
                          type='select'
                          id='role'
                          name='role'
                          bsSize='sm'
                          value={formData.role}
                          onChange={handleChange}
                        >
                          <option value=''>Select Role</option>
                          {roles.map(role => (
                            <option key={role._id} value={role.roleName}>{role.roleName}</option>
                          ))}
                        </Input>
                      </Col>
                      <Col md='6'>
                        <Label for='status'>Status</Label>
                        <Input
                          type='select'
                          id='status'
                          name='status'
                          bsSize='sm'
                          value={formData.status}
                          onChange={handleChange}
                        >
                          <option value={'Active'}>Active</option>
                          <option value={'InActive'}>InActive</option>
                        </Input>
                      </Col>
                      <Col xs='12'>
                        <Btn color='primary' type='submit' disabled={loading}>
                          {loading ? 'Processing...' : 'Add Data'}
                        </Btn>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default SubAdmin;
