import EdgeStyleContainer from "../../../Component/Buttons/EdgeStyle/EdgeStyle"

const EdgeStyle = () => {
  return (
    <div className='page-body'>
      <EdgeStyleContainer />
    </div>
  )
}

export default EdgeStyle