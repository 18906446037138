import RegisterWithBgImageContainer from "../../../../Component/OtherPages/Authentication/RegisterWithBgImage/RegisterWithBgImage"

const RegisterWithBgImage = () => {
  return (
    <div className='page-body'>
      <RegisterWithBgImageContainer />
    </div>
  )
}

export default RegisterWithBgImage