// import React, { useState, useEffect } from "react";
// import { Card, CardBody, CardHeader, Col } from "reactstrap";
// import { H4 } from "../../../../AbstractElements";
// import { SalesChartHeading } from "../../../../utils/Constant";
// import SalesChartDropdown from "./SalesChartDropdown";
// import ReactApexChart from "react-apexcharts";

// const primary = "#006666";
// const secondary = "#FE6A49";

// interface ChartData {
//   revenueData: number[];
//   ordersData: number[];
// }

// const SalesChart: React.FC = () => {
//   const [chartData, setChartData] = useState<ChartData>({
//     revenueData: [],
//     ordersData: [],
//   });

//   useEffect(() => {
//     fetch("https://mobileapp.hemexhealth.org/api/getChartData")
//       .then((response) => response.json())
//       .then((data) => {
//         const revenueData = data
//           .filter((entry: any) => entry.series_name === "Revenue")
//           .map((entry: any) => parseFloat(entry.value));
//         const ordersData = data
//           .filter((entry: any) => entry.series_name === "Orders")
//           .map((entry: any) => parseFloat(entry.value));

//         setChartData({ revenueData, ordersData });
//       })
//       .catch((error) => {
//         console.error("Error fetching chart data:", error);
//       });
//   }, []);

//   return (
//     <Col xl="6" className="box-col-7">
//       <Card>
//         <CardHeader className="sales-chart card-no-border pb-0">
//           <H4>{SalesChartHeading}</H4>
//           <SalesChartDropdown />
//         </CardHeader>
//         <CardBody className="p-2 pt-0">
//           <div className="sales-wrapper">
//             <ReactApexChart
//               options={{
//                 series: [
//                   {
//                     name: "Revenue",
//                     data: chartData.revenueData || [],
//                   },
//                   {
//                     name: "Orders",
//                     data: chartData.ordersData || [],
//                   },
//                 ],
//                 colors: [primary, secondary],
//                 chart: {
//                   height: 280,
//                   type: "area",
//                   toolbar: {
//                     tools: {
//                       zoom: false,
//                       zoomin: false,
//                       zoomout: false,
//                       reset: false,
//                       pan: false,
//                       download: false,
//                     },
//                   },
//                   dropShadow: {
//                     enabled: false,
//                     enabledOnSeries: undefined,
//                     top: 2,
//                     left: 1,
//                     blur: 2,
//                   },
//                 },
//                 legend: {
//                   show: false,
//                 },
//                 dataLabels: {
//                   enabled: false,
//                 },
//                 stroke: {
//                   curve: "smooth",
//                   width: 2,
//                 },
//                 fill: {
//                   gradient: {
//                     opacityFrom: 0.5,
//                     opacityTo: 0,
//                     shadeIntensity: 0.2,
//                   },
//                 },
//                 grid: {
//                   strokeDashArray: 5,
//                   xaxis: {
//                     lines: {
//                       show: true,
//                     },
//                   },
//                   yaxis: {
//                     lines: {
//                       show: false,
//                     },
//                   },
//                 },
//                 xaxis: {
//                   categories: [
//                     "Jan",
//                     "Feb",
//                     "Mar",
//                     "Apr",
//                     "May",
//                     "June",
//                     "July",
//                     "Aug",
//                     "Sep",
//                     "Oct",
//                     "Nov",
//                     "Dec",
//                   ],
//                   axisTicks: { show: false },
//                   axisBorder: { show: false },
//                   tooltip: { enabled: false },
//                 },
//                 responsive: [
//                   {
//                     breakpoint: 480,
//                     options: {
//                       series: [
//                         {
//                           name: "Revenue",
//                           data: chartData.revenueData.slice(0, 7),
//                         },
//                         {
//                           name: "Orders",
//                           data: chartData.ordersData.slice(0, 7),
//                         },
//                       ],
//                     },
//                   },
//                 ],
//                 tooltip: {
//                   x: { format: "dd/MM/YYYY", show: true },
//                   custom: ({
//                     series,
//                     seriesIndex,
//                     dataPointIndex,
//                   }: {
//                     series: any[];
//                     seriesIndex: number;
//                     dataPointIndex: number;
//                   }) => {
//                     return (
//                       '<div class="px-4 py-1 position-relative ">' +
//                       '<span class="fs-3">' +
//                       "K" +
//                       series[seriesIndex][dataPointIndex] +
//                       "</span>" +
//                       "<br>" +
//                       '<span class="align-middle text-content">' +
//                       (seriesIndex === 0 ? "Revenue" : "Orders") +
//                       "</span>" +
//                       "</div>"
//                     );
//                   },
//                 },
//               }}
//               series={[
//                 {
//                   name: "Revenue",
//                   data: chartData.revenueData || [],
//                 },
//                 {
//                   name: "Orders",
//                   data: chartData.ordersData || [],
//                 },
//               ]}
//               type="area"
//               height={280}
//             />
//           </div>
//         </CardBody>
//       </Card>
//     </Col>
//   );
// };

// export default SalesChart;
import React, { useState, useEffect } from "react";
import { Card, CardBody, CardHeader, Col } from "reactstrap";
import { H4 } from "../../../../AbstractElements";
import { SalesChartHeading } from "../../../../utils/Constant";
import SalesChartDropdown from "./SalesChartDropdown";
import ReactApexChart from "react-apexcharts";

const primary = "#006666";
const secondary = "#FE6A49";

interface ChartData {
  revenueData: number[];
  ordersData: number[];
}

const SalesChart: React.FC = () => {
  const [chartData, setChartData] = useState<ChartData>({
    revenueData: [],
    ordersData: [],
  });

  const sampleData = [
    { series_name: "Revenue", value: "1000.00" },
    { series_name: "Revenue", value: "3900.00" },
    { series_name: "Revenue", value: "2500.00" },
    { series_name: "Revenue", value: "7400.00" },
    { series_name: "Revenue", value: "5800.00" },
    { series_name: "Revenue", value: "55000.00" },
    { series_name: "Revenue", value: "4200.00" },
    { series_name: "Revenue", value: "5800.00" },
    { series_name: "Revenue", value: "3100.00" },
    { series_name: "Revenue", value: "7100.00" },
    { series_name: "Revenue", value: "1000.00" },
    { series_name: "Revenue", value: "8200.00" },
    { series_name: "Orders", value: "3800.00" },
    { series_name: "Orders", value: "4300.00" },
    { series_name: "Orders", value: "3400.00" },
    { series_name: "Orders", value: "3300.00" },
    { series_name: "Orders", value: "3000.00" },
    { series_name: "Orders", value: "1800.00" },
    { series_name: "Orders", value: "5900.00" },
    { series_name: "Orders", value: "5600.00" },
    { series_name: "Orders", value: "4200.00" },
    { series_name: "Orders", value: "6000.00" },
    { series_name: "Orders", value: "3900.00" },
    { series_name: "Orders", value: "8400.00" },
  ];
  
  useEffect(() => {
    // Instead of fetching data from API, use sampleData directly
    const revenueData = sampleData
      .filter((entry: any) => entry.series_name === "Revenue")
      .map((entry: any) => parseFloat(entry.value));
    const ordersData = sampleData
      .filter((entry: any) => entry.series_name === "Orders")
      .map((entry: any) => parseFloat(entry.value));

    setChartData({ revenueData, ordersData });
  }, []);

  return (
    <Col xl="6" className="box-col-7">
      <Card>
        <CardHeader className="sales-chart card-no-border pb-0">
          <H4>{SalesChartHeading}</H4>
          <SalesChartDropdown />
        </CardHeader>
        <CardBody className="p-2 pt-0">
          <div className="sales-wrapper">
            <ReactApexChart
              options={{
                series: [
                  {
                    name: "Revenue",
                    data: chartData.revenueData || [],
                  },
                  {
                    name: "Orders",
                    data: chartData.ordersData || [],
                  },
                ],
                colors: [primary, secondary],
                chart: {
                  height: 280,
                  type: "area",
                  toolbar: {
                    tools: {
                      zoom: false,
                      zoomin: false,
                      zoomout: false,
                      reset: false,
                      pan: false,
                      download: false,
                    },
                  },
                  dropShadow: {
                    enabled: false,
                    enabledOnSeries: undefined,
                    top: 2,
                    left: 1,
                    blur: 2,
                  },
                },
                legend: {
                  show: false,
                },
                dataLabels: {
                  enabled: false,
                },
                stroke: {
                  curve: "smooth",
                  width: 2,
                },
                fill: {
                  gradient: {
                    opacityFrom: 0.5,
                    opacityTo: 0,
                    shadeIntensity: 0.2,
                  },
                },
                grid: {
                  strokeDashArray: 5,
                  xaxis: {
                    lines: {
                      show: true,
                    },
                  },
                  yaxis: {
                    lines: {
                      show: false,
                    },
                  },
                },
                xaxis: {
                  categories: [
                    "Jan",
                    "Feb",
                    "Mar",
                    "Apr",
                    "May",
                    "June",
                    "July",
                    "Aug",
                    "Sep",
                    "Oct",
                    "Nov",
                    "Dec",
                  ],
                  axisTicks: { show: false },
                  axisBorder: { show: false },
                  tooltip: { enabled: false },
                },
                responsive: [
                  {
                    breakpoint: 480,
                    options: {
                      series: [
                        {
                          name: "Revenue",
                          data: chartData.revenueData.slice(0, 7),
                        },
                        {
                          name: "Orders",
                          data: chartData.ordersData.slice(0, 7),
                        },
                      ],
                    },
                  },
                ],
                tooltip: {
                  x: { format: "dd/MM/YYYY", show: true },
                  custom: ({
                    series,
                    seriesIndex,
                    dataPointIndex,
                  }: {
                    series: any[];
                    seriesIndex: number;
                    dataPointIndex: number;
                  }) => {
                    return (
                      '<div class="px-4 py-1 position-relative ">' +
                      '<span class="fs-3">' +
                      "K" +
                      series[seriesIndex][dataPointIndex] +
                      "</span>" +
                      "<br>" +
                      '<span class="align-middle text-content">' +
                      (seriesIndex === 0 ? "Revenue" : "Orders") +
                      "</span>" +
                      "</div>"
                    );
                  },
                },
              }}
              series={[
                {
                  name: "Revenue",
                  data: chartData.revenueData || [],
                },
                {
                  name: "Orders",
                  data: chartData.ordersData || [],
                },
              ]}
              type="area"
              height={280}
            />
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

export default SalesChart;
