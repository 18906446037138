import FaqContainer from "../../../Component/Miscellaneous/Faq/Faq"

const Faq = () => {
  return (
    <div className='page-body'>
      <FaqContainer />
    </div>
  )
}

export default Faq