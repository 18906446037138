import ButtonGroupContainer from "../../../Component/Buttons/ButtonGroup/ButtonGroup"

const ButtonGroup = () => {
  return (
    <div className='page-body'>
      <ButtonGroupContainer />
    </div>
  )
}

export default ButtonGroup