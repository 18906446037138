import TypeaheadContainer from "../../../../Component/Forms/FormsWidgets/Typeahead/Typeahead"

const Typeahead = () => {
  return (
    <div className='page-body'>
      <TypeaheadContainer />
    </div>
  )
}

export default Typeahead