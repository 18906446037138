import FormWizardOneContainer from "../../../../Component/Forms/FormsLayout/FormWizardOne/FormWizardOne"

const FormWizardOne = () => {
  return (
    <div className='page-body'>
      <FormWizardOneContainer />
    </div>
  )
}

export default FormWizardOne