import moment from "moment";
import {
  radialChart_1,
  radialChart_2,
  radialChart_3,
  radialChart_4,
  radialChart_5,
} from "./EcommerceChartData";
import {
  ActivityLogCardDataType,
  AllProjectTableBodyType,
  ImportantProjectListType,
  OnlineTimelineGroupType,
  OnlineTimelineItemsType,
  TodayWorkTableBodyType,
  TopClientTableBodyType,
  TotalProjectCardType,
  UserNewMessageDataType,
} from "../../../Types/Dashboard/Ecommerce";

export const totalProjectCardData:TotalProjectCardType[] = [
    {
        title:"Total Project",
        count:"1,523",
        svgIcon:"color-swatch",
        color:"primary",
        class:"project"
    },
    {
        title:"In Progress",
        count:"836",
        svgIcon:"tick-circle",
        color:"warning",
        class:"progress"
    },
    {
        title:"Complete",
        count:"475",
        svgIcon:"add-square",
        color:"secondary",
        class:"complete"
    },
    {
        title:"Upcoming",
        count:"189",
        svgIcon:"edit-2",
        color:"light",
        class:"upcoming"
    }
]

export const todayWorkTableBodyData: TodayWorkTableBodyType[] = [
  {
    title: "App Design",
    productName: "NFT Illustration...",
    assigned: "Assigned to",
    name: "Cody Fisher",
    days: "02",
    badge: "High",
    color: "primary",
  },
  {
    title: "App Design",
    productName: "NFT Illustration...",
    assigned: "Arlene McCoy",
    name: "Assigned to",
    days: "12",
    badge: "High",
    color: "primary",
  },
  {
    title: "Web Design",
    productName: "Appron’s 3D Co...",
    assigned: "Assigned to",
    name: "Kristin Watson",
    days: "12",
    badge: "Medium",
    color: "warning",
  },
  {
    title: "Desktop App",
    productName: "Rental Car",
    assigned: "Assigned to",
    name: "Darlene Robertson",
    days: "05",
    badge: "low",
    color: "secondary",
  },
  {
    title: "Template Design",
    productName: "E-commerce",
    assigned: "Assigned to",
    name: "Wade Warren",
    days: "31",
    badge: "High",
    color: "primary",
  },
  {
    title: "App Design",
    productName: "Food Delivery",
    assigned: "Assigned to",
    name: "Smith John",
    days: "20",
    badge: "Medium",
    color: "warning",
  },
];

export const importantProjectListData: ImportantProjectListType[] = [
  {
    image: "1.png",
    title: "Net Banking App",
    client: "Jordan",
    badge: "7 Days Left",
    color: "primary",
    startDate: "10 Oct, 2024",
    endDate: "15 Nov, 2024",
    rangeValue: 50,
    avatarGroup: ["18.png", "15.png", "19.png", "17.png"],
    messages: 2,
    svgIcon: "messages-2",
    comment: 18,
    linkIcon: "paperclip",
    link: 2,
    lastMeeting: "2 Nov 23,10:00 AM",
    nextMeeting: "8 Nov 23,09:45 AM",
  },
  {
    image: "2.png",
    title: "NFT Website",
    client: "Albert Flores",
    badge: "24 Days Left",
    color: "primary",
    startDate: "15 Oct, 2024",
    endDate: "01 Dec, 2024",
    rangeValue: 78,
    avatarGroup: ["24.png", "21.png", "23.png", "22.png"],
    messages: 5,
    svgIcon: "messages-2",
    comment: 18,
    linkIcon: "paperclip",
    link: 2,
    lastMeeting: "2 Nov 23,10:00 AM",
    nextMeeting: "8 Nov 23,09:45 AM",
  },
  {
    image: "3.png",
    title: "Marketing App",
    class:"box-col-none marketing-app-card",
    client: "Jane Cooper",
    badge: "31 Days Left",
    color: "primary",
    startDate: "01 Nov, 2024",
    endDate: "18 Dec, 2024",
    rangeValue: 35,
    avatarGroup: ["25.png", "26.png", "27.png", "28.png"],
    messages: 8,
    svgIcon: "messages-2",
    comment: 20,
    linkIcon: "paperclip",
    link: 7,
    lastMeeting: "6 Nov 23,2:56 PM",
    nextMeeting: "10 Nov 23, 7:12 AM",
  },
];

export const allProjectTableBodyData: AllProjectTableBodyType[] = [
  {
    chartId: "widgetsChart1",
    chartOption: radialChart_1,
    title: "Pet App Design",
    svgIcon: "messages-2",
    messages: 20,
    linkIcon: "paperclip",
    link: 7,
    userName: "Darrell Steward",
    email: "darrells@example.com",
    endDate: "15 Nov, 2024",
    days: "8 Days Left",
    assigned: "Team Roha",
    member: "12 Member",
    status: "Active",
    color: "primary",
  },
  {
    chartId: "widgetsChart2",
    chartOption: radialChart_2,
    title: "Chain Desktop App",
    svgIcon: "messages-2",
    messages: 20,
    linkIcon: "paperclip",
    link: 7,
    userName: "Eleanor Pena",
    email: "pena12@example.com",
    endDate: "20 Nov, 2024",
    days: "13 Days Left",
    assigned: "Team Suresh",
    member: "10 Member",
    status: "On Hold",
    color: "warning",
  },
  {
    chartId: "widgetsChart3",
    chartOption: radialChart_3,
    title: "Business Web Design",
    svgIcon: "messages-2",
    messages: 20,
    linkIcon: "paperclip",
    link: 7,
    userName: "Robert Fox",
    email: "foxxxx8s@example.com",
    endDate: "22 Nov, 2024",
    days: "15 Days Left",
    assigned: "Team Liza",
    member: "7 Member",
    status: "Pending",
    color: "secondary",
  },
  {
    chartId: "widgetsChart4",
    chartOption: radialChart_4,
    title: "NFT App Design",
    svgIcon: "messages-2",
    messages: 20,
    linkIcon: "paperclip",
    link: 7,
    userName: "Arlene McCoy",
    email: "arlene78@example.com",
    endDate: "28 Nov, 2024",
    days: "21 Days Left",
    assigned: "Team Sulekha",
    member: "9 Member",
    status: "Active",
    color: "primary",
  },
  {
    chartId: "widgetsChart5",
    chartOption: radialChart_5,
    title: "Digital Avtar Web Design",
    svgIcon: "messages-2",
    messages: 20,
    linkIcon: "paperclip",
    link: 7,
    userName: "Courtney Henry",
    email: "henry45@example.com",
    endDate: "2 Nov, 2024",
    days: "25 Days Left",
    assigned: "Team Shreena",
    member: "12 Member",
    status: "Active",
    color: "primary",
  },
];

export const topClientTableBodyData: TopClientTableBodyType[] = [
  {
    image: "29.png",
    color: "warning",
    name: "Jenny Bell",
    country: "India",
    email: "jennybell@gmail.com",
    phoneNumber: "+84 342 556 555",
  },
  {
    image: "30.png",
    color: "warning",
    name: "Albert Flores",
    country: "UK",
    email: "albert78@gmail.com",
    phoneNumber: "+77 445 551 629",
  },
  {
    image: "33.png",
    color: "warning",
    name: "Jane Cooper",
    country: "London",
    email: "jane145@gmail.com",
    phoneNumber: "+56 955 510 831",
  },
  {
    image: "31.png",
    color: "warning",
    name: "Devon Lane",
    country: "America",
    email: "devom796@gmail.com",
    phoneNumber: "+56 955 570 095",
  },
  {
    image: "32.png",
    color: "warning",
    name: "Cody Fisher",
    country: "Canada",
    email: "cody7895@gmail.com",
    phoneNumber: "+226 795 552 31",
  },
];

export const onlineTimelineGroup: OnlineTimelineGroupType[] = [
  { id: 1, title: "12am" },
  { id: 2, title: "1am" },
  { id: 3, title: "2am" },
  { id: 4, title: "3am" },
  { id: 5, title: "4am" },
  { id: 6, title: "5am" },
  { id: 7, title: "6am" },
  { id: 8, title: "7am" },
  { id: 9, title: "8am" },
  { id: 10, title: "9am" },
  { id: 11, title: "10am" },
  { id: 12, title: "11am" },
  { id: 13, title: "12pm" },
  { id: 14, title: "1pm" },
  { id: 15, title: "2pm" },
  { id: 16, title: "3pm" },
  { id: 17, title: "4pm" },
  { id: 18, title: "5pm" },
  { id: 19, title: "6pm" },
  { id: 20, title: "7pm" },
  { id: 21, title: "8pm" },
  { id: 22, title: "9pm" },
  { id: 23, title: "10pm" },
  { id: 24, title: "11pm" },
];

export const onlineTimelineItems: OnlineTimelineItemsType[] = [
  {
    id: 1,
    group: 1,
    title: "11:20am | Mon - 7 Nov, 2024",
    start_time: moment(),
    end_time: moment().add(1, "hour"),
  },
  {
    id: 2,
    group: 2,
    title: "3:00pm | Thu - 10 Nov, 2024",
    start_time: moment().add(-0.5, "hour"),
    end_time: moment().add(0.5, "hour"),
  },
  {
    id: 3,
    group: 1,
    title: "7:30pm | Tue - 8 Nov, 2024",
    start_time: moment().add(2, "hour"),
    end_time: moment().add(3, "hour"),
  },
];

export const userNewMessageData: UserNewMessageDataType[] = [
  {
    image: "39.png",
    status: true,
    name: "Maren Ross",
    message: "Hey, What’s today update ?",
  },
  {
    image: "40.png",
    status: false,
    name: "Brooklyn Simmons",
    message: "I know it will work.",
  },
  {
    image: "41.png",
    status: true,
    name: "Floyd Miles",
    message: "Sir, Can remove part in des...",
  },
  {
    image: "42.png",
    status: false,
    name: "Dianne Russell",
    message: "So, what is my next work ?",
  },
  {
    image: "43.png",
    status: true,
    name: "Darlene Robertson",
    message: "Can we add that here ?",
  },
  {
    image: "44.png",
    status: true,
    name: "Jenny Wilson",
    message: "Hey, What’s today update ?",
  },
  {
    image: "45.png",
    status: true,
    name: "Ralph Edwards",
    message: "ok, send it.",
  },
  {
    image: "15.png",
    status: true,
    name: "Ronald Richards",
    message: "Thank you !!!",
  },
  {
    image: "47.png",
    status: false,
    name: "Courtney Henry",
    message: "No, you’ve to do one more variant.",
  },
];

export const activityLogCardData: ActivityLogCardDataType[] = [
  {
    image: "26.png",
    userName: "Jenny Wilson",
    time: "Today 10:45 AM",
    activity: "Commented on",
    apps: "NFT App",
    comment:
      "This smithe design looks great!! but this page as i  mention belove.",
  },
  {
    image: "34.png",
    userName: "Darlene Robertson",
    time: "Today 10:43 AM",
    activity: "Shared File to",
    apps: "Barkha",
    comment:
      "Food Delivery App figma & Ai file shared to a .zip file to make it easier to send.",
  },
  {
    image: "35.png",
    userName: "Seema Joshi",
    time: "Today 10:42 AM",
    activity: "Meeting",
    apps: "Eva Website",
    comment:
      "You can send the AI file as attachment service and share a download link.",
  },
  {
    image: "44.png",
    userName: "Elara Winter",
    time: "Today 06:45 AM",
    activity: "Meeting",
    apps: "Eva Website",
    comment: "Metting about next page design of eva website.",
  },
  {
    image: "38.png",
    userName: "Arya Shwanno",
    time: "Today 05:51 AM",
    activity: "Add new screen",
    apps: "Pet App",
    comment:
      "Make sure your AI file is cloud storage like Google Drive or Dropbox.",
  },
];
