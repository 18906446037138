import BlogSingleContainer from "../../../../Component/Miscellaneous/Blog/BlogSingle/BlogSingle"

const BlogSingle = () => {
  return (
    <div className='page-body'>
      <BlogSingleContainer />
    </div>
  )
}

export default BlogSingle