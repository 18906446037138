import ComingSoonSimpleContainer from "../../../../Component/OtherPages/ComingSoon/ComingSoonSimple/ComingSoonSimple"

const ComingSoonSimple = () => {
  return (
    <div className='page-body'>
      <ComingSoonSimpleContainer />
    </div>
  )
}

export default ComingSoonSimple