export const raisedDefaultButtonsData = [
  {
    toolTipText: "btn btn-pill btn-primary btn-air-primary",
    color: "primary",
    tittle: "Primary Button",
    id: "default1",
  },
  {
    toolTipText: "btn btn-pill btn-secondary btn-air-secondary",
    color: "secondary",
    id: "default2",
    tittle: "Secondary Button",
  },
  {
    toolTipText: "btn btn-pill btn-success btn-air-success",
    color: "success",
    id: "default3",
    tittle: "Success Button",
  },
  {
    toolTipText: "btn btn-pill btn-info btn-air-info",
    color: "info",
    id: "default4",
    tittle: "Info Button",
  },
  {
    toolTipText: "btn btn-pill btn-warning btn-air-warning",
    id: "default5",
    color: "warning",
    tittle: "Warning Button",
  },
  {
    toolTipText: "btn btn-pill btn-danger btn-air-danger",
    id: "default6",
    color: "danger",
    tittle: "Danger Button",
  },
  {
    toolTipText: "btn btn-pill btn-light btn-air-light",
    color: "light",
    id: "default7",
    tittle: "Light Button",
  },
];

export const raisedGradientButtonsData = [
  {
    toolTipText: "btn btn-pill btn-primary-gradien btn-air-primary",
    color: "primary-gradien",
    tittle: "Primary Button",
    id: "gradientButtons1",
  },
  {
    toolTipText: "btn btn-pill btn-secondary-gradien btn-air-secondary",
    color: "secondary-gradien",
    id: "gradientButtons2",
    tittle: "Secondary Button",
  },
  {
    toolTipText: "btn btn-pill btn-success-gradien btn-air-success",
    color: "success-gradien",
    id: "gradientButtons3",
    tittle: "Success Button",
  },
  {
    toolTipText: "btn btn-pill btn-info-gradien btn-air-info",
    color: "info-gradien",
    id: "gradientButtons4",
    tittle: "Info Button",
  },
  {
    toolTipText: "btn btn-pill btn-warning-gradien btn-air-warning",
    id: "gradientButtons5",
    color: "warning-gradien",
    tittle: "Warning Button",
  },
  {
    toolTipText: "btn btn-pill btn-danger-gradien btn-air-danger",
    id: "gradientButtons6",
    color: "danger-gradien",
    tittle: "Danger Button",
  },
  {
    toolTipText: "btn btn-pill btn-light-gradien btn-air-light",
    id: "gradientButtons7",
    color: "light-gradien",
    tittle: "Light Button",
    className: "text-dark",
  },
];

export const raisedLargeButtonsData = [
  {
    toolTipText: "btn btn-pill btn-primary btn-lg btn-air-primary",
    id: "largeButton1",
    color: "primary",
    size: "lg",
    tittle: "Primary Button",
  },
  {
    toolTipText: "btn btn-pill btn-secondary btn-lg btn-air-secondary",
    id: "largeButton2",
    color: "secondary",
    size: "lg",
    tittle: "Secondary Button",
  },
  {
    toolTipText: "btn btn-pill btn-success btn-lg btn-air-success",
    id: "largeButton3",
    size: "lg",
    color: "success",
    tittle: "Success Button",
  },
  {
    toolTipText: "btn btn-pill btn-info btn-lg btn-air-info",
    id: "largeButton4",
    color: "info",
    tittle: "Info Button",
    size: "lg",
  },
  {
    toolTipText: "btn btn-pill btn-warning btn-lg btn-air-warning",
    id: "largeButton5",
    color: "warning",
    size: "lg",
    tittle: "Warning Button",
  },
  {
    toolTipText: "btn btn-pill btn-danger btn-lg btn-air-danger",
    id: "largeButton6",
    size: "lg",
    color: "danger",
    tittle: "Danger Button",
  },
  {
    toolTipText: "btn btn-pill btn-light btn-lg btn-air-light",
    id: "largeButton7",
    color: "light",
    size: "lg",
    tittle: "Light Button",
  },
];

export const raisedSmallButtonsData = [
  {
    toolTipText: "btn btn-pill btn-primary btn-sm btn-air-primary",
    id: "smallButton1",
    color: "primary",
    size: "sm",
    tittle: "Primary Button",
  },
  {
    toolTipText: "btn btn-pill btn-secondary btn-sm btn-air-secondary",
    id: "smallButton2",
    color: "secondary",
    size: "sm",
    tittle: "Secondary Button",
  },
  {
    toolTipText: "btn btn-pill btn-success btn-sm btn-air-success",
    id: "smallButton3",
    size: "sm",
    color: "success",
    tittle: "Success Button",
  },
  {
    toolTipText: "btn btn-pill btn-info btn-sm btn-air-info",
    id: "smallButton4",
    color: "info",
    tittle: "Info Button",
    size: "sm",
  },
  {
    toolTipText: "btn btn-pill btn-warning btn-sm btn-air-warning",
    id: "smallButton5",
    color: "warning",
    size: "sm",
    tittle: "Warning Button",
  },
  {
    toolTipText: "btn btn-pill btn-danger btn-sm btn-air-danger",
    id: "smallButton6",
    size: "sm",
    color: "danger",
    tittle: "Danger Button",
  },
  {
    toolTipText: "btn btn-pill btn-light btn-sm btn-air-light",
    id: "smallButton7",
    color: "light",
    size: "sm",
    tittle: "Light Button",
  },
];

export const raisedExtraSmallButtonsData = [
  {
    toolTipText: "btn btn-pill btn-primary btn-xs btn-air-primary",
    id: "extraSmallButton1",
    color: "primary",
    size: "xs",
    tittle: "Primary Button",
  },
  {
    toolTipText: "btn btn-pill btn-secondary btn-xs btn-air-secondary",
    id: "extraSmallButton2",
    color: "secondary",
    size: "xs",
    tittle: "Secondary Button",
  },
  {
    toolTipText: "btn btn-pill btn-success btn-xs btn-air-success",
    id: "extraSmallButton3",
    size: "xs",
    color: "success",
    tittle: "Success Button",
  },
  {
    toolTipText: "btn btn-pill btn-info btn-xs btn-air-info",
    id: "extraSmallButton4",
    color: "info",
    tittle: "Info Button",
    size: "xs",
  },
  {
    toolTipText: "btn btn-pill btn-warning btn-xs btn-air-warning",
    id: "extraSmallButton5",
    color: "warning",
    size: "xs",
    tittle: "Warning Button",
  },
  {
    toolTipText: "btn btn-pill btn-danger btn-xs btn-air-danger",
    id: "extraSmallButton6",
    size: "xs",
    color: "danger",
    tittle: "Danger Button",
  },
  {
    toolTipText: "btn btn-pill btn-light btn-xs btn-air-light",
    id: "extraSmallButton7",
    color: "light",
    size: "xs",
    tittle: "Light Button",
  },
];

export const raisedDisabledButtonsData = [
  {
    toolTipText: "btn btn-pill btn-primary disabled btn-air-primary",
    disabled: true,
    color: "primary",
    tittle: "Disabled",
    id: "active1",
  },
  {
    toolTipText: "btn btn-pill btn-secondary disabled btn-air-secondary",
    disabled: true,
    color: "secondary",
    id: "active2",
    tittle: "Disabled",
  },
  {
    toolTipText: "btn btn-pill btn-success disabled btn-air-success",
    disabled: true,
    color: "success",
    id: "active3",
    tittle: "Disabled",
  },
  {
    toolTipText: "btn btn-pill btn-info disabled btn-air-info",
    disabled: true,
    color: "info",
    id: "active4",
    tittle: "Disabled",
    className: "text-white",
  },
  {
    toolTipText: "btn btn-pill btn-warning disabled btn-air-warning",
    disabled: true,
    id: "active4",
    color: "warning",
    tittle: "Disabled",
  },
  {
    toolTipText: "btn btn-pill btn-danger disabled btn-air-danger",
    disabled: true,
    id: "active5",
    color: "danger",
    tittle: "Disabled",
  },
  {
    toolTipText: "btn btn-pill btn-light disabled btn-air-light",
    disabled: true,
    color: "light",
    id: "active6",
    tittle: "Disabled",
  },
];

export const raisedActiveButtonsData = [
  {
    active: true,
    color: "primary",
    tittle: "Active",
  },
  {
    active: true,
    color: "secondary",
    tittle: "Active",
  },
  {
    active: true,
    color: "success",
    tittle: "Active",
  },
  {
    active: true,
    color: "info",
    tittle: "Active",
  },
  {
    active: true,
    color: "warning",
    tittle: "Active",
  },
  {
    active: true,
    color: "danger",
    tittle: "Active",
  },
  {
    active: true,
    color: "light",
    tittle: "Active",
  },
];

export const raisedOutlineButtonsData = [
  {
    toolTipText: "btn btn-pill btn-outline-primary btn-air-primary",
    outline: true,
    color: "primary",
    tittle: "Primary Button",
    id: "outLine1",
  },
  {
    toolTipText: "btn btn-pill outline-btn-secondary btn-air-secondary",
    outline: true,
    color: "secondary",
    id: "outLine2",
    tittle: "Secondary Button",
  },
  {
    toolTipText: "btn btn-pill btn-outline-success btn-air-success",
    outline: true,
    color: "success",
    id: "outLine3",
    tittle: "Success Button",
  },
  {
    toolTipText: "btn btn-pill btn-outline-info btn-air-info",
    outline: true,
    color: "info",
    id: "outLine4",
    tittle: "Info Button",
  },
  {
    toolTipText: "btn btn-pill btn-outline-warning btn-air-warning",
    outline: true,
    id: "outLine5",
    color: "warning",
    tittle: "Warning Button",
  },
  {
    toolTipText: "btn btn-pill btn-outline-danger btn-air-danger",
    outline: true,
    id: "outLine6",
    color: "danger",
    tittle: "Danger Button",
  },
  {
    toolTipText: "btn btn-pill btn-outline-light btn-air-light",
    outline: true,
    color: "light",
    id: "outLine7",
    tittle: "Light Button",
  },
];

export const raisedBoldBorderOutlineButtonsData = [
  {
    toolTipText: "btn btn-pill btn-outline-primary-2x",
    outline: true,
    color: "primary-2x",
    tittle: "Primary Button",
    id: "boldBorderOutLine1",
  },
  {
    toolTipText: "btn btn-pill btn-outline-secondary-2x",
    outline: true,
    color: "secondary-2x",
    id: "boldBorderOutLine2",
    tittle: "Secondary Button",
  },
  {
    toolTipText: "btn btn-pill btn-outline-success-2x",
    outline: true,
    color: "success-2x",
    id: "boldBorderOutLine3",
    tittle: "Success Button",
  },
  {
    toolTipText: "btn btn-pill btn-outline-info-2x",
    outline: true,
    color: "info-2x",
    id: "boldBorderOutLine4",
    tittle: "Info Button",
  },
  {
    toolTipText: "btn btn-pill btn-outline-warning-2x",
    outline: true,
    id: "boldBorderOutLine5",
    color: "warning-2x",
    tittle: "Warning Button",
  },
  {
    toolTipText: "btn btn-pill btn-outline-danger-2x",
    outline: true,
    id: "boldBorderOutLine6",
    color: "danger-2x",
    tittle: "Danger Button",
  },
  {
    toolTipText: "btn btn-pill btn-outline-light-2x",
    outline: true,
    color: "light-2x",
    id: "boldBorderOutLine7",
    tittle: "Light Button",
    className: "text-dark",
  },
];

export const raisedOutlineDisabledButtonsData = [
  {
    toolTipText: "btn btn-pill btn-outline-primary btn-air-primary",
    outline: true,
    color: "primary",
    tittle: "Disabled",
    id: "outLineDisabled1",
    disabled: true,
  },
  {
    toolTipText: "btn btn-pill outline-btn-secondary btn-air-secondary",
    outline: true,
    color: "secondary",
    id: "outLineDisabled2",
    disabled: true,
    tittle: "Secondary Button",
  },
  {
    toolTipText: "btn btn-pill btn-outline-success btn-air-success",
    outline: true,
    color: "success",
    id: "outLineDisabled3",
    disabled: true,
    tittle: "Disabled",
  },
  {
    toolTipText: "btn btn-outline-info btn-air-info",
    outline: true,
    color: "info",
    id: "outLineDisabled4",
    disabled: true,
    tittle: "Disabled",
  },
  {
    toolTipText: "btn btn-pill btn-outline-warning btn-air-warning",
    outline: true,
    id: "outLineDisabled5",
    disabled: true,
    color: "warning",
    tittle: "Disabled",
  },
  {
    toolTipText: "btn btn-outline-danger btn-air-danger",
    outline: true,
    id: "outLineDisabled6",
    disabled: true,
    color: "danger",
    tittle: "Disabled",
  },
  {
    toolTipText: "btn btn-pill btn-outline-light btn-air-light",
    outline: true,
    color: "light",
    id: "outLineDisabled7",
    disabled: true,
    tittle: "Disabled",
    className: "text-dark",
  },
];

export const raisedOutlineLargeButtonsData = [
  {
    toolTipText: "btn btn-pill btn-outline-primary btn-large btn-air-primary",
    size: "lg",
    outline: true,
    color: "primary",
    tittle: "Primary Button",
    id: "outLineLarge1",
  },
  {
    toolTipText:
      "btn btn-pill btn-outline-secondary btn-large btn-air-secondary",
    size: "lg",
    outline: true,
    color: "secondary",
    id: "outLineLarge2",
    tittle: "Secondary Button",
  },
  {
    toolTipText: "btn btn-pill btn-outline-success btn-large btn-air-success",
    size: "lg",
    outline: true,
    color: "success",
    id: "outLineLarge3",
    tittle: "Success Button",
  },
  {
    toolTipText: "btn btn-pill btn-outline-info btn-large btn-air-info",
    size: "lg",
    outline: true,
    color: "info",
    id: "outLineLarge4",
    tittle: "Info Button",
  },
  {
    toolTipText: "btn btn-pill btn-outline-warning btn-large btn-air-warning",
    size: "lg",
    outline: true,
    id: "outLineLarge5",
    color: "warning",
    tittle: "Warning Button",
  },
  {
    toolTipText: "btn btn-pill btn-outline-danger btn-large btn-air-danger",
    size: "lg",
    outline: true,
    id: "outLineLarge6",
    color: "danger",
    tittle: "Danger Button",
  },
  {
    toolTipText: "btn btn-outline-light btn-large btn-air-light",
    size: "lg",
    outline: true,
    color: "light",
    id: "outLineLarge7",
    tittle: "Light Button",
  },
];

export const raisedOutlineSmallButtonsData = [
  {
    toolTipText: "btn btn-pill btn-outline-primary btn-sm btn-air-light",
    size: "sm",
    outline: true,
    color: "primary",
    tittle: "Primary Button",
    id: "outLineSmall1",
  },
  {
    toolTipText: "btn btn-pill btn-outline-secondary btn-sm btn-air-secondary",
    size: "sm",
    outline: true,
    color: "secondary",
    id: "outLineSmall2",
    tittle: "Secondary Button",
  },
  {
    toolTipText: "btn btn-outline-success btn-sm btn-air-success",
    size: "sm",
    outline: true,
    color: "success",
    id: "outLineSmall3",
    tittle: "Success Button",
  },
  {
    toolTipText: "btn btn-pill btn-outline-info btn-sm btn-air-info",
    size: "sm",
    outline: true,
    color: "info",
    id: "outLineSmall4",
    tittle: "Info Button",
  },
  {
    toolTipText: "btn btn-pill btn-outline-warning btn-sm btn-air-warning",
    size: "sm",
    outline: true,
    id: "outLineSmall5",
    color: "warning",
    tittle: "Warning Button",
  },
  {
    toolTipText: "btn btn-pill btn-outline-danger btn-sm btn-air-danger",
    size: "sm",
    outline: true,
    id: "outLineSmall6",
    color: "danger",
    tittle: "Danger Button",
  },
  {
    toolTipText: "btn btn-pill btn-outline-light btn-sm btn-air-light",
    size: "sm",
    outline: true,
    color: "light",
    id: "outLineSmall7",
    tittle: "Light Button",
  },
];

export const raisedOutlineExtraSmallButtonsData = [
  {
    toolTipText: "btn btn-pill btn-outline-primary btn-xs btn-air-primary",
    size: "xs",
    outline: true,
    color: "primary",
    tittle: "Primary Button",
    id: "outLineExtraSmall1",
  },
  {
    toolTipText: "btn btn-pill btn-outline-secondary btn-xs btn-air-secondary",
    size: "xs",
    outline: true,
    color: "secondary",
    id: "outLineExtraSmall2",
    tittle: "Secondary Button",
  },
  {
    toolTipText: "btn btn-pill btn-outline-success btn-xs btn-air-success",
    size: "xs",
    outline: true,
    color: "success",
    id: "outLineExtraSmall3",
    tittle: "Success Button",
  },
  {
    toolTipText: "btn btn-pill btn-outline-info btn-xs btn-air-info",
    size: "xs",
    outline: true,
    color: "info",
    id: "outLineExtraSmall4",
    tittle: "Info Button",
  },
  {
    toolTipText: "btn btn-pill btn-outline-warning btn-xs btn-air-warning",
    size: "xs",
    outline: true,
    id: "outLineExtraSmall5",
    color: "warning",
    tittle: "Warning Button",
  },
  {
    toolTipText: "btn btn-pill btn-outline-danger btn-xs btn-air-danger",
    size: "xs",
    outline: true,
    id: "outLineExtraSmall6",
    color: "danger",
    tittle: "Danger Button",
  },
  {
    toolTipText: "btn btn-pill btn-outline-light btn-xs btn-air-light",
    size: "xs",
    outline: true,
    color: "light",
    id: "outLineExtraSmall7",
    tittle: "Light Button",
  },
];

export const CustomStateButtonsHeadingData = [
  {
    text: "The ",
    code: ".btn",
  },
  {
    text: " class used with ",
    code: "<button>",
  },
  {
    text: " ",
    code: "<a>",
  },
  {
    text: " and ",
    code: "<input>",
  },
  {
    text: " elements.",
  },
];

export const raisedDefaultButtonsHeadingData = [
  {
    text: "Add ",
    code: ".btn-pill",
  },
  {
    text: " Add ",
    code: ".btn-air-* ",
  },
  {
    text: " Class For Raised Button",
  },
];

export const raisedLargeButtonsHeadingData = [
  {
    text: "Add ",
    code: ".btn-pill,.btn-air-*",
  },
  {
    text: " Add ",
    code: ".btn-lg ",
  },
  {
    text: " class for large size buttons",
  },
];

export const raisedSmallButtonsHeadingData = [
  {
    text: "Add ",
    code: ".brn-pill,.btn-air-*",
  },
  {
    text: " Add ",
    code: ".btn-sm ",
  },
  {
    text: " class for small size buttons",
  },
];

export const raisedExtraSmallButtonsHeadingData = [
  {
    text: "Add ",
    code: ".btn-pill",
  },
  {
    text: " Add ",
    code: ".btn-air-* ",
  },
  {
    text: " Add ",
    code: ".btn-xs",
  },
  {
    text: " class for extra small size buttons",
  },
];

export const raisedActiveButtonsHeadingData = [
  {
    text: "Add ",
    code: ".active",
  },
  {
    text: " class for active state",
  },
];

export const raisedDisabledButtonsHeadingData = [
  {
    text: "Add ",
    code: "disabled",
  },
  {
    text: " class or ",
    code: "disabled='disabled'",
  },
  {
    text: " attribute for disabled button",
  },
];

export const raisedOutlineButtonsHeadingData = [
  {
    text: "Add ",
    code: ".btn-pill,.btn-air-*",
  },
  {
    text: " Add ",
    code: ".btn-outline-* ",
  },
  {
    text: " class for border button",
  },
];

export const raisedBoldBorderOutlineButtonsHeadingData = [
  {
    text: "Add ",
    code: ".btn-pill,.btn-air-*",
  },
  {
    text: " Add ",
    code: ".btn-outline-*-2x ",
  },
  {
    text: " class for bold outline",
  },
];

export const raisedOutlineLargeButtonsHeadingData = [
  {
    text: "Add ",
    code: ".btn-pill,.btn-air-*",
  },
  {
    text: " Add ",
    code: ".btn-outline-* ",
  },
  {
    text: " class for outline and ",
    code: ".btn-lg",
  },
  {
    text: " class for large button",
  },
];

export const raisedOutlineSmallButtonsHeadingData = [
  {
    text: "Add ",
    code: ".btn-pill,.btn-air-*",
  },
  {
    text: " Add ",
    code: ".btn-outline-* ",
  },
  {
    text: " class for outline and ",
    code: ".btn-sm",
  },
  {
    text: " class for small button",
  },
];

export const raisedOutlineExtraSmallButtonsHeadingData = [
  {
    text: "Add ",
    code: ".btn-pill,.btn-air-*",
  },
  {
    text: " Add ",
    code: ".btn-outline-* ",
  },
  {
    text: " class for outline and ",
    code: ".btn-xs",
  },
  {
    text: " class for extra small button",
  },
];

export const raisedDisabledOutlineButtonsHeadingData = [
  {
    text: "Add ",
    code: ".disabled",
  },
  {
    text: " class or ",
    code: "disabled='disabled'",
  },
  {
    text: " attribute for disabled state",
  },
];

export const raisedGraddienButtonsHeadingData = [
  {
    text: "Add ",
    code: ".btn-pill,.btn-air-*",
  },
  {
    text: " Add ",
    code: ".btn-*-gradien ",
  },
  {
    text: " class for gradien button",
  },
];

export const customButtonsSpan = [
  {
    text: "The ",
    code: ".btn",
  },
  {
    text: " class used with ",
    code: "<button>",
  },
  {
    text: " ",
    code: "<a>",
  },
  {
    text: " and ",
    code: "<input>",
  },
  {
    text: " elements.",
  },
];
