import React, { useState, useEffect } from 'react';
import { Container, Row, Card, CardBody, Table, FormGroup, Label, Input, Button } from 'reactstrap';
import Breadcrumbs from '../../CommonElements/Breadcrumbs/Breadcrumbs';
import CommonCardHeader from '../../CommonElements/CommonCardHeader/CommonCardHeader';

interface Role {
  _id: string;
  name: string;
  identifier: string;
}

const AddRole: React.FC = () => {
  const [roles, setRoles] = useState<Role[]>([]);
  const [permissions, setPermissions] = useState<{ [role: string]: { [action: string]: boolean } }>({});
  const [roleName, setRoleName] = useState('');
  const [status, setStatus] = useState('');

  useEffect(() => {
    fetch('https://mobileapp.hemexhealth.org/api/role')
      .then(response => response.json())
      .then(data => {
        setRoles(data);
        const initialPermissions: { [role: string]: { [action: string]: boolean } } = {};
        data.forEach((role: { name: string | number; }) => {
          initialPermissions[role.name] = {
            view: false,
            add: false,
            edit: false,
            delete: false
          };
        });
        setPermissions(initialPermissions);
      })
      .catch(error => console.error('Error fetching roles:', error));
  }, []);

  const handleCheckboxChange = (roleName: string, permission: string) => {
    setPermissions(prevPermissions => ({
      ...prevPermissions,
      [roleName]: {
        ...prevPermissions[roleName],
        [permission]: !prevPermissions[roleName][permission]
      }
    }));
  };

  const handleSave = () => {
    const payload = {
      roleName,
      status,
      permissions: Object.entries(permissions).map(([role, actions]) => ({
        role,
        identifier: roles.find(r => r.name === role)?.identifier || '',
        actions
      }))
    };

    fetch('https://mobileapp.hemexhealth.org/api/roles', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    })
      .then(response => response.json())
      .then(data => {
        console.log('Response from API:', data);
        setRoleName('');
        setStatus('');
        setPermissions({});
      })
      .catch(error => console.error('Error saving permissions:', error));
  };

  return (
    <div className='page-body'>
      <Breadcrumbs mainTitle={'Sub Admin'} parent={'Sub Admin'} />
      <Container fluid>
        <Row>
          <Card>
            <CommonCardHeader title={'Permissions Sub Admin'} />
            <CardBody className="p-0">
              <div className="p-3">
                <Label>Role Name</Label>
                <Input type="text" value={roleName} onChange={(e) => setRoleName(e.target.value)} />
              </div>
              <div className="custom-scrollbar">
                <Table responsive>
                  <thead>
                    <tr>
                      <th>Role</th>
                      <th>View</th>
                      <th>Add</th>
                      <th>Edit</th>
                      <th>Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {roles.map(role => (
                      <tr key={role._id}>
                        <td>{role.name}</td>
                        <td>
                          <FormGroup check>
                            <Label check>
                              <Input type="checkbox" checked={permissions[role.name]?.view || false} onChange={() => handleCheckboxChange(role.name, 'view')} />
                            </Label>
                          </FormGroup>
                        </td>
                        <td>
                          <FormGroup check>
                            <Label check>
                              <Input type="checkbox" checked={permissions[role.name]?.add || false} onChange={() => handleCheckboxChange(role.name, 'add')} />
                            </Label>
                          </FormGroup>
                        </td>
                        <td>
                          <FormGroup check>
                            <Label check>
                              <Input type="checkbox" checked={permissions[role.name]?.edit || false} onChange={() => handleCheckboxChange(role.name, 'edit')} />
                            </Label>
                          </FormGroup>
                        </td>
                        <td>
                          <FormGroup check>
                            <Label check>
                              <Input type="checkbox" checked={permissions[role.name]?.delete || false} onChange={() => handleCheckboxChange(role.name, 'delete')} />
                            </Label>
                          </FormGroup>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
              <div className="p-3">
                <Label>Status</Label>
                <Input type="select" value={status} onChange={(e) => setStatus(e.target.value)}>
                  <option value="">Select Status</option>
                  <option value="Active">Active</option>
                  <option value="Inactive">Inactive</option>
                </Input>
                <Button color="primary" className="mt-3" onClick={handleSave}>Save</Button>
              </div>
            </CardBody>
          </Card>
        </Row>
      </Container>
    </div>
  );
};

export default AddRole;
