import LoginWithBackGroundImageContainer from "../../../../Component/OtherPages/Authentication/LoginWithBackGroundImage/LoginWithBackGroundImage"

const LoginWithBackGroundImage = () => {
  return (
    <div className='page-body'>
      <LoginWithBackGroundImageContainer />
    </div>
  )
}

export default LoginWithBackGroundImage