import ReactstrapTabsContainer from "../../../Component/Ui-Kits/ReactstrapTabs/ReactstrapTabs"

const ReactstrapTabs = () => {
  return (
    <div className='page-body'>
      <ReactstrapTabsContainer />
    </div>
  )
}

export default ReactstrapTabs