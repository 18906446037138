import DatePickerContainer from "../../../../Component/Forms/FormsWidgets/DatePicker/DatePicker"

const DatePicker = () => {
  return (
    <div className='page-body'>
      <DatePickerContainer />
    </div>
  )
}

export default DatePicker