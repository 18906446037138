import ComingSoonWithBgImageContainer from "../../../../Component/OtherPages/ComingSoon/ComingSoonWithBgImage/ComingSoonWithBgImage"

const ComingSoonWithBgImage = () => {
  return (
    <div className='page-body'>
      <ComingSoonWithBgImageContainer />
    </div>
  )
}

export default ComingSoonWithBgImage