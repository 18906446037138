import AlertContainer from "../../../Component/Ui-Kits/Alert/Alert"

const Alert = () => {
  return (
    <div className='page-body'>
      <AlertContainer />
    </div>
  )
}

export default Alert