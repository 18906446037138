import ProductPageContainer from "../../../../Component/Application/Ecommerce/ProductPage/ProductPage"

const ProductPage = () => {
  return (
    <div className='page-body'>
      <ProductPageContainer />
    </div>
  )
}

export default ProductPage