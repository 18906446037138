import WishListContainer from "../../../../Component/Application/Ecommerce/WishList/WishList"

const WishList = () => {
  return (
    <div className='page-body'>
      <WishListContainer />
    </div>
  )
}

export default WishList