import LoginWithValidationContainer from "../../../../Component/OtherPages/Authentication/LoginWithValidation/LoginWithValidation"

const LoginWithValidation = () => {
  return (
    <div className='page-body'>
      <LoginWithValidationContainer />
    </div>
  )
}

export default LoginWithValidation