import { AddNewProductType, LatestOrdersTableBodyType, LiveProductTableBodyType, SaleHistoryBodyType, SalesChartDropdownType, TotalRevenueType, TotalSalesType } from "../../Types/Dashboard/Default";
import { revenueChartData, totalOrderChartData } from "./DashboardChart";

export const totalRevenueData:TotalRevenueType[] = [
    {
        title:"Total Users",
        amount:"2",
        icon:"ArrowUpRight",
        result:"10.00% from last year",
        chartOptions:revenueChartData,
        chartType:"bar",
        chartClass:"total-revenue-chart"
    },
    {
        title:"Total Devices",
        amount:"5,452",
        icon:"ArrowUpRight",
        result:"15.00% from last week",
        chartOptions:totalOrderChartData,
        chartType:"area",
        chartClass:"total-order"
    }
]

export const totalSalesData:TotalSalesType[] =[
    {
        icon:"activity",
        color:"primary-light",
        title:"Total Active Devices",
        amount:"1,216",
        detail:"We have sale +18.2k this week."
    },
    {
        icon:"people",
        color:"warning-light",
        title:"Total Test Completed",
        amount:"1,54,156",
        detail:"We have total +3.5k tests this week."
    },
    {
        icon:"task-square",
        color:"light",
        title:"Total Orders",
        amount:"5,125",
        detail:"We have total +5k orders this week."
    },
    {
        icon:"money-recive",
        color:"danger-light",
        title:"Total Inactive Devices",
        amount:"129",
        detail:"We got +66k refund this week."
    }
]

export const saleHistoryBodyData:SaleHistoryBodyType[] = [
    {
        title:"Missing Values: Incomplete data or fields with no values.",
        amount:"₹1500.14",
        state:"Pune,MH",
        time:"50 min ago"
    },
    {
        title:"Incorrect Data: Data that is erroneously entered or recorded.",
        amount:"₹1800.87",
        state:"Bengaluru,KA",
        time:"40 min ago"
    },
    {
        title:"Processing Speed: Slow execution of queries or data operations.",
        amount:"₹2000.84",
        state:"Mumbai,MH",
        time:"35 min ago"
    },
    {
        title:"Inadequate documentation regarding data sources, transformations.",
        amount:"₹1500.14",
        state:"Mumbai,MH",
        time:"50 min ago"
    },
    {
        title:"Insufficient controls over who can access or modify the dataset.",
        amount:"₹780.25",
        state:"Hyderabad,TS",
        time:"28 min ago"
    }
]

export const salesChartDropdownData:SalesChartDropdownType[] = [
    {
        color:"primary",
        title:"Negative"
    },
    {
        color:"warning",
        title:"NA"
    },
    {
        color:"secondary",
        title:"Positive"
    }
]

export const addNewProductData:AddNewProductType[] = [
    {
        color:"primary",
        icon:"box-add",
        title:"Add New Product",
        subTitle:"Images are crucial showcasing"
    },
    {
        color:"primary",
        icon:"receipt-disscount",
        title:"Add Disscount",
        subTitle:"The product images to the platform"
    },
]

export const liveProductTableBodyData:LiveProductTableBodyType[] = [
    {
        image:"6.png",
        productName:"Formal Shirts",
        type:"Men"
    },
    {
        image:"7.png",
        productName:"Loafers",
        type:"Men"
    },
    {
        image:"8.png",
        productName:"Jeans",
        type:"Women"
    },
    {
        image:"9.png",
        productName:"Saree",
        type:"Women"
    }
]

export const latestOrdersTableBodyData:LatestOrdersTableBodyType[] = [
    {
       image :"1.png",
       orderName:"Mumbai,MH",
       orderId:"4859578",
       name:"Amit Shah",
       email:"amith14@gmail.com",
       status:"Active",
       svgIcon:"fream",
       amount:"₹1500.45",
       paymentDetail:"Google Pay",
       color:"primary" 
    },
    {
        image :"2.png",
        orderName:"Bengaluru,KA",
        orderId:"4875566",
        name:"Arlene McCoy",
        email:"arlene1@gmail.com",
        status:"Pending",
        svgIcon:"fream",
        amount:"₹785.62",
        paymentDetail:"Credit Card",
        color:"warning" 
     },
     {
        image :"3.png",
        orderName:"Pune,MH",
        orderId:"7894561",
        name:"Marvin McKinney",
        email:"marvin4@gmail.com",
        status:"Inactive",
        svgIcon:"fream",
        amount:"₹2000.02",
        paymentDetail:"Debit Card",
        color:"secondary" 
     },
     {
        image :"4.png",
        orderName:"Lucknow,UP",
        orderId:"1234567",
        name:"Annette Black",
        email:"black45@gmail.com",
        status:"Cancelled",
        svgIcon:"fream",
        amount:"₹1589.25",
        paymentDetail:"Pay Pal",
        color:"light" 
     }
]