import ContainerChart from "../../../Component/Widgets/Charts/ContainerChart"

const Charts = () => {
  return (
    <div className='page-body'>
      <ContainerChart />
    </div>
  )
}

export default Charts