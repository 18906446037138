import BasicInitContainer from "../../../../Component/Tables/DataTables/BasicInit/BasicInit"

const BasicInit = () => {
  return (
    <div className='page-body'>
      <BasicInitContainer />
    </div>
  )
}

export default BasicInit