import SocialAppContainer from "../../../Component/Application/SocialApp/SocialApp"

const SocialApp = () => {
  return (
    <div className='page-body'>
      <SocialAppContainer />
    </div>
  )
}

export default SocialApp