import RadioCheckboxContainer from "../../../../Component/Forms/FormsControl/RadioCheckbox/RadioCheckbox"

const RadioCheckbox = () => {
  return (
    <div className='page-body'>
      <RadioCheckboxContainer />
    </div>
  )
}

export default RadioCheckbox