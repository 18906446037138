export const registerHorizontalData = [
    { tittle: "personal", detail: "Add personal details" },
    { tittle: "Address", detail: "Add additional info" },
    { tittle: "Message", detail: "Add message(optional)" },
    { tittle: "Done", detail: "Complete.. !" },
];

export const samplePageData = [
    {
        text:"lorem ipsum dolor sit amet, consectetur adipisicing elit"
    }
]