import ImageCropperContainer from "../../../Component/BonusUi/ImageCropper/ImageCropper"

const ImageCropper = () => {
  return (
    <div className='page-body'>
      <ImageCropperContainer />
    </div>
  )
}

export default ImageCropper