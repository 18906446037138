import BasicCardContainer from "../../../Component/BonusUi/BasicCard/BasicCard"

const BasicCard = () => {
  return (
    <div className='page-body'>
      <BasicCardContainer />
    </div>
  )
}

export default BasicCard