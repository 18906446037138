import PricingContainer from "../../../../Component/Application/Ecommerce/Pricing/Pricing"

const Pricing = () => {
  return (
    <div className='page-body'>
      <PricingContainer />
    </div>
  )
}

export default Pricing