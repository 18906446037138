import TodoContainer from "../../../Component/Application/Todo/Todo"

const Todo = () => {
  return (
    <div className='page-body'>
      <TodoContainer />
    </div>
  )
}

export default Todo